import React, { PropsWithChildren, useEffect } from "react";
import { useState } from "react";
import { getCheckIcon, getWarningIcon } from "../services/icons";
import Tabs from "./Tabs";

type Props = PropsWithChildren<{
  data?: any;
  modal: string;
  onClose: () => void;
  onSubmit: (data: any[]) => void;
}>;

export default function UpdateModalBody({ data, onSubmit }: Props) {

  return (
    <>
      <div className="mx-auto max-w-update-info pb-8 pt-16">
        <div className="invite-full-screen-dialog advisor-dialog advisors">
          <div className="">
            <div className="mb-8">
              <h3 className="font-pprightgothic text-4xl font-medium uppercase tracking-widestXl">
                kundkort
              </h3>
            </div>
            <Tabs client={data} onSubmit={onSubmit}/>
          </div>
        </div>
      </div>
    </>
  );
}
