import React, {PropsWithChildren, useEffect} from "react";
import ImportModalBody from "./import-modal-body";

type Props = PropsWithChildren<{
  onClose: () => void;
}>;

const ModalConfirm = ({onClose}: Props) => {

  useEffect(() => {
    const closeOnEscapeKey = (e: any) => e.key === "Escape" ? onClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  return (
    <div
      className="fixed z-50 flex h-full w-full flex-col top-0"
      style={{backgroundColor: "rgba(0, 0, 0, .75)"}}
      onClick={() => {
        // close modal when outside of modal is clicked
        onClose();
      }}
    >
      <div className="flex max-h-[90%] w-full max-w-modal-confirm justify-center mx-auto my-auto"
           onClick={e => {
             e.stopPropagation();
           }}>
        <div className="relative w-full flex flex-col rounded bg-[#FFFEFB] drop-shadow-xl">
          <div className="absolute right-2 top-2">
            <img
              src="/images/icon-close-dark.svg"
              alt="close"
              width="18"
              height="18"
              className="box-content h-fit max-w-none cursor-pointer p-4"
              onClick={onClose}
            />
          </div>
          <div className="overflow-auto">
            <div className="max-w-confirm mx-auto py-28">
              <div className="invite-full-screen-dialog">
                <div className="">
                  <div>
                    <div className="">
                      <h3 className="font-inter text-[40px] font-semibold mb-0 italic">Är du säker på att du vill
                        aktivera påminnelser för alla? </h3>
                      <p className="font-inter font-normal text-[26px] mb-3 leading-9">Aktiverar du för alla så kommer
                        dina föregående inställningar att försvinna</p>
                    </div>
                    <div className="flex mt-20">
                      <button className="flex items-center text-xl uppercase font-inter font-semibold text-[#0C2632] rounded bg-[#9EBEA8] hover:bg-[#9EBEA8]/70 border border-[#9EBEA8] py-6 px-20 mr-4">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd" clip-rule="evenodd"
                            d="M36.9008 11.466C37.5547 12.1007 37.5703 13.1453 36.9356 13.7992L18.7856 32.4992C18.4721 32.8221 18.0404 33.003 17.5903 33C17.1403 32.9969 16.711 32.8101 16.402 32.4829L7.05199 22.5829C6.4263 21.9204 6.45613 20.8761 7.11864 20.2504C7.78114 19.6247 8.82544 19.6546 9.45114 20.3171L17.6178 28.9642L34.5676 11.5008C35.2022 10.8469 36.2468 10.8313 36.9008 11.466Z"
                            fill="#0C2632"/>
                        </svg>
                        Ja, Aktivera
                      </button>
                      <button className="flex items-center text-xl uppercase font-inter font-semibold text-[#0C2632] rounded rounded bg-transparent hover:bg-[#c8cecab3]/70 border border-[#0C2632] py-6 px-20">
                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M31.3484 12.6499L12.6484 31.3499M12.6484 12.6499L31.3484 31.3499" stroke="#0C2632"
                            stroke-width="3.3" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Nej, avbryt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
