import React, {useState} from "react";
import {getRightArrow} from "../services/icons";

interface FamilyMember {
  onFamilyMemberUpdate: (data: string) => void;
  roleArray: string[];
  familyArray: string[];
  activePerson: string;
  setActivePerson: React.Dispatch<React.SetStateAction<string>>;
}

export default function FamilyMember({roleArray, familyArray,  onFamilyMemberUpdate, activePerson, setActivePerson}: FamilyMember) {
  const handleFamilyMember = (familyMember:any) => {
    onFamilyMemberUpdate(familyMember);
    setActivePerson((prevActivePerson) =>
      prevActivePerson === familyMember.personNumber ? "" : familyMember.personNumber
    );
  };
  return (
    <div className="block-1 px-4 pb-9 bg-[#FFFEFB] border border-[#CECABA] rounded">
      {roleArray.map((role:string) => {
        const roleRecords = familyArray.filter((person: any) => person.role === role);
        if (roleRecords.length > 0) {
          return (
            <div className="person pt-8" key={role}>
              <div
                className="person-title uppercase text-[#736F62] text-[12px] pb-2 inter-medium tracking-[.2em] border-b border-[#CECABA]">{role}</div>
              {roleRecords.map((person: any) => (
                <div
                  key={person.personNumber}
                  onClick={() => handleFamilyMember(person)}>
                  <div className={`person-detail cursor-pointer flex gap-4 items-center pt-6 ${activePerson === person.personNumber ? " ml-4" : ""}`}>
                    <div
                      className={`person-img flex justify-center items-center w-[48px] h-[48px] rounded-full italic inter-black text-[32px] overflow-hidden ${activePerson === person.personNumber ? " bg-[#273F5C] text-[#D1D8E0]" : " bg-[#CECABA] text-[#736F62]"}`}>{person.name.substring(0, 1)}{person.surname.substring(0, 1)}</div>
                    <div className="flex flex-col"><p
                      className={`flex items-center gap-2 ${activePerson === person.personNumber ? " text-[18px]" : ""}`}>{person.name} {person.surname} {getRightArrow(activePerson === person.personNumber ? " 2.5" : "1.5")}</p> <p
                      className="text-[11px] text-[#736F62]">{person.personNumber}</p></div>
                  </div>
                </div>
              ))}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};