import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getFromStorage } from "../services/storage";

import Layout from "../components/Layout";
import Navigation from "../components/navigation";
import Article from "../components/invite-modal-article";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import toast, { Toaster } from "react-hot-toast";
import Modal from "../components/Modal";
import { GlobalState } from "../context/GlobalState";
import { postSendEmail } from "../services/ida";

let data = require("../assets/data/data.json");

interface Resource {
  id: string;
  title: string;
  thumb: string;
  link: string;
  body: string;
  checked: boolean;
}

export default function Resources() {
  const { fenixPartnerUser, setFenixPartnerUser } = useContext(GlobalState);
  const [bookModal, setBookModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [articles, setArticles] = useState<Array<Resource>>([]);
  const [value, copy] = useCopyToClipboard();
  const [fenixLocalPartnerUser, setFenixLocalPartnerUser] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    setArticles(data.resources);
  }, [data.checklist]);

  const displayEmailNotice1 = () => {
    toast.success("Texten har kopierats", {
      style: {
        padding: "30px 45px",
        borderRadius: "5px",
        backgroundColor: "#9EBEA8",
        color: "#fff",
        fontSize: "20px",
        fontWeight: 600,
        textTransform: "uppercase",
        maxWidth: "unset",
      },
      icon: "✓",
    });
  };

  const displayEmailNotice2 = () => {
    toast.success("Texten har kopierats", {
      style: {
        padding: "30px 45px",
        borderRadius: "5px",
        backgroundColor: "#9EBEA8",
        color: "#fff",
        fontSize: "20px",
        fontWeight: 600,
        textTransform: "uppercase",
        maxWidth: "unset",
      },
      icon: "✓",
    });
  };

  useEffect(() => {
    document.body.classList.add("resources");
    const fenixToken = getFromStorage("FenixATkn", null);
    if (fenixToken) {
    } else {
      navigate("/");
    }

    return () => {
      document.body.classList.remove("resources");
    };
  }, []);

  useEffect(() => {
    if (
      fenixPartnerUser.data.firstName == "" &&
      fenixPartnerUser.data.lastName == ""
    ) {
      const storageFenixPartnerUser = getFromStorage(
        "fnxPartnerUser",
        fenixPartnerUser
      );
      setFenixPartnerUser(storageFenixPartnerUser);
      // setFenixLocalPartnerUser(storageFenixPartnerUser);
    } else {
      setFenixLocalPartnerUser(fenixPartnerUser);
    }
  }, [fenixPartnerUser]);

  const handleSubmit = (data: any) => {
    // e.preventDefault();
    let messageSubject = "";
    let messageBody =
      "Hej, \n\n" +
      data.formData.companyName +
      "\nPartner User: " +
      data.formData.firstName +
      " " +
      data.formData.lastName +
      " (" +
      data.formData.personNumber +
      ")\n";

    switch (data.formData.paketType) {
      case "pakettyp_1":
        messageSubject = "Din partner har lagt en ny beställning";
        messageBody =
          "Hej, " +
          data.formData.companyName +
          "\n\nPartner " +
          data.formData.firstName +
          " " +
          data.formData.lastName +
          " (" +
          data.formData.personNumber +
          ") har lagt följande beställning:" +
          "\n\nPakettyp: Bjud in fler kunder till Fenix Livsplanering, " +
          data.formData.paketQuantity +
          "\n\nDu kan nu hantera ordern i IDA och följa upp med partner.\n\nHa en fin dag!";
        break;
      case "pakettyp_2":
        messageSubject = "Din partner har lagt en ny beställning";
        messageBody =
          "Hej, " +
          data.formData.companyName +
          "\n\nPartner " +
          data.formData.firstName +
          " " +
          data.formData.lastName +
          " (" +
          data.formData.personNumber +
          ") har lagt följande beställning:" +
          "\n\nPakettyp: Bjud in fler kollegor till partnerportalen, " +
          data.formData.paketQuantity +
          "\n\nDu kan nu hantera ordern i IDA och följa upp med partner.\n\nHa en fin dag!";
        break;
      case "pakettyp_3":
        messageSubject = "Din partner önskar uppgradera sitt paket";
        messageBody =
          "Hej, " +
          data.formData.companyName +
          "\n\nPartner " +
          data.formData.firstName +
          " " +
          data.formData.lastName +
          " (" +
          data.formData.personNumber +
          ") är intresserad av att uppgradera till följande nivå:" +
          data.formData.upgradeType +
          "\n\nDu kan nu hantera ordern i IDA och följa upp med partner.\n\nHa en fin dag!";
        break;
    }

    let dataEmail = {
      subject:
        "BESTÄLLNING PartnerId " + fenixLocalPartnerUser.data.creds.partnerId,
      message: messageBody,
    };

    postSendEmail(
      fenixLocalPartnerUser.data.creds.tokenAuth,
      fenixLocalPartnerUser.data.creds.partnerId,
      dataEmail
    )
      .then((dataEmail: any) => {
        toast.success(
          "Tack för din beställning. \nVi återkommer med bekräftelse på \ndin beställning inom en arbetsdag.",
          {
            duration: 6000,
            style: {
              padding: "30px 45px",
              borderRadius: "5px",
              backgroundColor: "#9EBEA8",
              color: "#fff",
              fontSize: "20px",
              fontWeight: 600,
              textTransform: "uppercase",
              maxWidth: "unset",
            },
            icon: "✓",
          }
        );
      })
      .catch((e: any) => {
        toast.error(
          "Något gick fel. \nDin förfrågan kunde inte skickas, \nförsök gärna igen eller kontakta oss \nom problemet kvarstår.",
          {
            style: {
              padding: "30px 55px",
              borderRadius: "5px",
              backgroundColor: "#EA3324",
              color: "#fff",
              fontSize: "20px",
              fontWeight: 600,
              fontFamily: "Inter",
              textTransform: "uppercase",
              maxWidth: "unset",
            },
            icon: "✕",
          }
        );
        console.log(e);
      });
  };

  function handleShowPackages() {
    setBookModal(true);
    setTypeModal("package");
  }

  const onModalClose = () => {
    setBookModal(false);
  };

  return (
    <Layout modalOpen={bookModal} mainClass="relative flex flex-grow flex-col bg-[#FFFEFB] bg-[center_top] bg-no-repeat">
      <Navigation />
      <div className="mx-auto w-full max-w-internal">
        <div className="relative z-10 box-border rounded-b border-x border-b border-solid border-light-grey bg-white px-3 lg:px-24 lg:pb-40 pb-20 pt-10 lg:pt-24">
          <div className="support-details flex flex-col">
            <div className="bg-white">
              <div className="mb-7">
                <h1 className="font-pprightgothic text-2xl lg:text-4xl font-medium uppercase tracking-widestXl">
                  Resurser
                </h1>
              </div>
              <p className="font-inter text-lg font-light">
                Förutom att skicka inbjudningar till dina kunder direkt från
                portalen har vi här nedan förberett två mailtexter som du kan
                kopiera och skicka från valfritt mailverktyg. Glöm inte att
                personalisera texten genom att lägga till kundens namn och en
                personlig signatur. Här hittar du också en casefilm som
                tydliggör kundens resa i produkten, klicka på bilden för att se
                videon och ta del av länken som med fördel kan delas vidare med
                dina kunder.
              </p>
            </div>
            <div className="mt-10 flex flex-col lg:flex-row gap-3">
              <div className="w-full lg:w-1/2">
                <div className="package-title mb-2.5 font-inter font-medium uppercase">
                  Lägg beställning
                </div>
                <div className="package-description mb-2.5 font-interlight text-xxs tracking-wide">
                  Vill du ge fler av dina kunder möjlighet att påbörja sin
                  livsplanering? Eller vill du bjuda in fler av dina kollegor
                  till partnerportalen? Klicka på knappen här bredvid för att
                  beställa, vi kontaktar dig senast nästa arbetsdag för att
                  bekräfta din beställning.
                </div>
              </div>
              <div className="w-full flex lg:w-1/2 items-center justify-center">
                <button
                  id="lägg_beställning"
                  onClick={handleShowPackages}
                  className="mb-2.5 flex cursor-pointer items-center justify-center self-start rounded bg-deep-blue px-4 py-2.5 font-interbold uppercase leading-8 text-white hover:bg-deep-blue/80"
                >
                  Lägg beställning{" "}
                  <img
                    src="/images/arrow-white.svg"
                    alt="icon plus"
                    width={18}
                    height={20}
                    className="ml-2"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="mb-12 mt-20 border-b border-light-grey pb-3 font-inter text-base font-medium uppercase tracking-widest">
            Mailtexter
          </div>
          <div className="mat-form-field-flex mb-16">
            <div className="relative flex flex-col">
              <label className="font-base mb-3 pl-3 font-inter font-medium tracking-widestXl">
                Bjud in dina kunder till att påbörja sin Livsplanering
              </label>
              <textarea
                className="rounded-lg border border-solid border-[#CFCAB8] bg-[#CFCAB8]/10 py-4 pl-3 pr-4 lg:pl-4 lg:pr-14"
                name="clientEmailCopy"
                id="client-email-copy"
                defaultValue={data.emailTemplateDefault[0].text}
              ></textarea>
              <img
                src="../images/icon-copy.svg"
                alt="icon copy"
                width={25}
                height={25}
                className="absolute bottom-4 right-2 lg:right-5 cursor-pointer"
                onClick={() => {
                  copy(data.emailTemplateDefault[0].text);
                  displayEmailNotice1();
                }}
              />
            </div>
          </div>
          <div className="mat-form-field-flex mb-16">
            <div className="relative flex flex-col">
              <label className="font-base mb-3 pl-3 font-inter font-medium tracking-widestXl">
                Påminn dina kunder om att logga in och sätta igång med
                sin Livsplanering
              </label>
              <textarea
                className="rounded-lg border border-solid border-[#CFCAB8] bg-[#CFCAB8]/10 py-4 pl-3 pr-4 lg:pl-4 lg:pr-14"
                name="clientEmailCopy"
                id="client-email-copy"
                defaultValue={data.emailTemplateDefault[1].text}
              ></textarea>
              <img
                src="../images/icon-copy.svg"
                alt="icon copy"
                width={25}
                height={25}
                className="absolute bottom-4 right-2 lg:right-5 cursor-pointer"
                onClick={() => {
                  copy(data.emailTemplateDefault[1].text);
                  displayEmailNotice2();
                }}
              />
            </div>
          </div>
          <div className="mt-24 border-b border-light-grey pb-3 font-inter text-base font-medium uppercase tracking-widest">
            media
          </div>
          <div className="article-grid">
            {articles.map((resource, index) => (
              <Article
                key={index}
                index={index}
                id={resource.id}
                title={resource.title}
                image={new URL(
                  "../assets/images/image_thumb_tn.jpg",
                  import.meta.url
                ).href}
                link={resource.link}
                body={resource.body}
              />
            ))}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      {bookModal && (
        <Modal
          modal={typeModal}
          onClose={onModalClose}
          onSubmit={handleSubmit}
          data={fenixPartnerUser.data}
        ></Modal>
      )}
    </Layout>
  );
}
