import React from "react";

interface TabContent {
  id?: string;
  activeTab: string;
  children: any;
}

export default function  TabContent ({id, activeTab, children}:TabContent)  {
  return (
    activeTab === id ? <div className="tabContent pb-0">
        { children }
      </div>
      : null
  );
};