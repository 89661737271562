import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoaderScreen from "../components/LoaderScreen";
import {
  getProfileInfo,
  getUserData,
  refreshAccessToken,
  updateProfileInfo,
} from "../services/ida";
import {
  conversationKey,
  getFromStorage,
  removeFromStorage,
  setInStorage,
  userKey,
} from "../services/storage";
import { dataDeepCopy } from "../services/utils";
import {
  UserActionType,
  UserContextType,
  UserType,
  UserRefreshTokenActionType,
} from "../types/user";

const initialUser: UserType = {
  info: null,
  accessToken: "",
  expDate: 0,
  refreshToken: "",
};

const UserContext = createContext({} as UserContextType);
const UserDispatchContext = createContext<Dispatch<UserActionType> | any>(
  {} as any
);

// const UserDispatchContext = createContext({} as UserActionType);
// const UserDispatchContext = createContext<Dispatch<UserActionType> | null>(
//   null
// );

const userReducer = (state: UserType, action: UserActionType) => {
  switch (action.type) {
    case "set-data": {
      const age =
        new Date().getFullYear() -
        parseInt(action.payload.personNumber.slice(0, 4));
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expDate: action.payload.expDate,
        profile: action.payload.profile,
        info: {
          ...state.info,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          personNumber: action.payload.personNumber,
          age: age,
        },
      };
    }
    case "refresh-token":
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        expDate: action.payload.expDate,
      };
    case "set-profile":
      return {
        ...state,
        profile: action.payload,
      };
    case "set-from-storage":
      return {
        ...state,
        ...action.payload,
      };
    case "clear":
      return initialUser;
    default:
      return state;
  }
};

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, dispatch] = useReducer(userReducer, initialUser);
  const navigate = useNavigate();

  const populateFromStorage = () => {
    const localUser = getFromStorage(userKey, null);
    let data = initialUser;
    if (localUser) {
      data = dataDeepCopy(localUser);

      // force fetch data from api
      // data.accessTokenExpires = 0;
    }

    dispatch({ type: "set-from-storage", payload: data });
  };
  const saveToStorage = () => {
    setInStorage(userKey, user);
  };
  const checkExpiredToken = () => {
    if (!user) {
      return false;
    }
    if (user && Date.now() >= user.expDate) {
      // return false;
      logout();
      refreshAccessToken(user.accessToken, user.refreshToken)
        .then((data) => {
          dispatch({
            type: "refresh-token",
            payload: {
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              expDate: Date.now() + parseInt(data.expiresIn) * 1000,
            },
          });
        })
        .catch((e) => console.log(e));
    }
    return true;
  };

  const fetchProfileData = (accessToken: any) => {
    return getProfileInfo(accessToken)
      .then((data) => {
        dispatch({
          type: "set-profile",
          payload: data,
        });
        return data;
      })
      .catch((e) => console.log(e));
  };
  const fetchUserData = (accessToken: any) => {
    return false;
    return getUserData(accessToken)
      .then((data) => {})
      .catch((e) => console.log(e));
  };

  const logout = () => {
    dispatch({ type: "clear" });
    removeFromStorage(userKey);
    (window as Window).location = "/";
    // navigate("/");
  };

  const userObj = {
    user,
    populateFromStorage,
    saveToStorage,
    fetchProfileData,
    fetchUserData,
    checkExpiredToken,
    logout,
  };

  return (
    <UserContext.Provider value={userObj}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}

export function useUserDispatchContext() {
  return useContext(UserDispatchContext);
}

interface UserProps {
  children?: ReactNode;
  // any props that come into the component
}

export function RequireUserAuth({ children, ...props }: UserProps) {
  const { user, checkExpiredToken, logout } = useUserContext();

  useEffect(() => {
    if (!user) {
      return;
    }
    const isValid = checkExpiredToken();
    if (!isValid) {
      logout();
    }
  }, [user]);

  if (!user) {
    return <LoaderScreen />;
  }

  return children;
}
