import * as Sentry from "@sentry/react";

import React, {
  useEffect,
  useState,
  useReducer,
  useMemo,
  useContext,
  useRef,
} from "react";
import toast, { Toaster } from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import {useUserContext} from "../context/UserContext";

import Layout from "../components/Layout";
import Navigation from "../components/navigation";
import Modal from "../components/Modal";
import Checkbox from "../components/Checkbox";
import jwt_decode from "jwt-decode";
import { GlobalState } from "../context/GlobalState";
import {
  PaginationState,
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  SortingState,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  Row,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

import {
  getFromStorage,
  removeFromStorage,
  setInStorage,
} from "../services/storage";

import { Person } from "../types/person";

import {
  apiUrl,
  getPartnerPackage,
  getPartnerPackageBatches,
  getPartnerPackageBatch,
  getPartnerUser,
  getPartnerUserCustomerSummary,
  putPartnerUserCustomerSummaryEmail,
  getPartnerCustomers,
  postPartnerCustomers,
  postCreateInvitation,
  postSendEmail,
  postPartnerUserRequestDataConsent,
  postPartnerCustomerEvent,
} from "../services/ida";
import {
  getCheckIcon,
  getSortingIcon,
  getWarningIcon,
} from "../services/icons";

const PAGINATION_ITEMS_VISIBLE = 11;
const PAGINATION_ITEMS_LEFT = 5;
const PAGINATION_ITEMS_RIGHT = 5;

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
    multiSelector: FilterFn<unknown>;
    customFilterFn: FilterFn<unknown>;
  }

  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const filterHeaders = ["Aktiverat paket", "Status", "Tillgänglig data"];

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const multiSelectFilter: FilterFn<any> = (row, columnId, value) => {
  return true;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

let dataList = require("../assets/data/data.json");

interface Client {
  id: string;
  index: number;
  label: string;
  birth: string;
  value: string;
  phone: string;
  checked: boolean;
  checkHandler: any;
}

interface Resource {
  id: string;
  title: string;
  thumb: string;
  link: string;
  body: string;
  checked: boolean;
}

interface FilterChip {
  id: string;
  value: any;
}

interface Package {
  id: string;
  type: string;
  index: number;
  label: string;
  value: string;
  checked: boolean;
  checkHandler: any;
  batches?: any;
}

interface Status {
  id: string;
  type: string;
  index: number;
  label: string;
  value: string;
  checked: boolean;
  checkHandler: any;
}

interface Automation {
  id: string;
  type: string;
  index: number;
  label: string;
  value: string;
  checked: boolean;
  checkHandler: any;
}

export default function Dashboard() {
  const {user, fetchUserData, saveToStorage} = useUserContext();
  const refOutside = useRef<null | HTMLDivElement>(null);
  const { fenixPartnerUser, setFenixPartnerUser } = useContext(GlobalState);

  const [articles, setArticles] = useState<Array<Resource>>([]);
  const [users, setUsers] = useState<Array<Client>>([]);
  const [bookModal, setBookModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isChildOpen, setIsChildOpen] = useState(0);
  const [filterChips, setFilterChips] = useState<Array<FilterChip>>([]);
  const [packages, setPackages] = useState<Array<Package>>([]);
  const [statuses, setStatuses] = useState<Array<Status>>([]);
  const [automations, setAutomations] = useState<Array<Automation>>([]);
  const [partnerPackage, setPartnerPackage] = useState([]);
  const [partnerUser, setPartnerUser] = useState<any>();
  const [partnerPackageBatch, setPartnerPackageBatch] = useState([]);
  const [partnerPackageBatchShort, setPartnerPackageBatchShort] = useState([]);
  const [partnerDashboard, setPartnerDashboard] = useState<any>({
    availablePackageInvites: "~",
    countOfContractCreatedCustomers: "~",
    countOfInvitationsSent: "~",
    countOfLoggedInInvitedCustomers: "~",
  });
  const [isFocused, setIsFocused] = useState(false);
  const [decodedFenixToken, setDecodedFenixToken] = useState<any>();
  const [partnerCustomers, setPartnerCustomers] = useState<any>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [fenixCreds, setFenixCreds] = useState({
    tokenAuth: "",
    partnerId: 0,
  });

  const navigate = useNavigate();

  const updateCheckStatusClients = (index: any) => {
    setUsers(
      users.map((user, currentIndex) =>
        currentIndex === index ? { ...user, checked: !user.checked } : user
      )
    );
  };

  const updateCheckPackages = (index: any) => {
    setPackages(
      packages.map((pack, currentIndex) =>
        currentIndex === index ? { ...pack, checked: !pack.checked } : pack
      )
    );
  };

  const updateCheckStatuses = (index: any) => {
    setStatuses(
      statuses.map((stat, currentIndex) =>
        currentIndex === index ? { ...stat, checked: !stat.checked } : stat
      )
    );
  };

  const updateCheckAutomation = (index: any) => {
    setAutomations(
      automations.map((aut, currentIndex) =>
        currentIndex === index ? { ...aut, checked: !aut.checked } : aut
      )
    );
  };

  useEffect(() => {
    setUsers(dataList.checklist);
    setPackages(dataList.packages);
    setStatuses(dataList.statuses);
    setAutomations(dataList.automations);
    setArticles(dataList.resources);
  }, [
    dataList.checklist,
    dataList.packages,
    dataList.statuses,
    dataList.automation,
    dataList.resources,
    partnerPackage,
  ]);

  useEffect(() => {
    document.body.classList.add("dashboard");
    const fenixToken = getFromStorage("FenixATkn", null);
    if (fenixToken) {
      setDecodedFenixToken(jwt_decode(fenixToken));
    } else {
      navigate("/");
    }

    return () => {
      document.body.classList.remove("dashboard");
    };
  }, []);

  useEffect(() => {}, [isFocused]);

  useEffect(() => {}, [fenixPartnerUser]);

  useEffect(() => {
    partnerPackage.map((onePackage: any) => {
      partnerPackageBatchShort.map((oneBatch: any) => {
        getPartnerPackageBatch(
          fenixCreds.tokenAuth,
          fenixCreds.partnerId,
          onePackage.id,
          oneBatch.id
        )
          .then((dataBatch: any) => {
            let curPartnerPackageBatch: any = partnerPackageBatchShort;
            curPartnerPackageBatch[oneBatch.id] = oneBatch;
            // if (curPartnerPackageBatch[oneBatch.id]) {
            curPartnerPackageBatch[oneBatch.id].data = dataBatch;
            setPartnerPackageBatch(curPartnerPackageBatch);
            // }
          })
          .catch((e: any) => console.log(e));
      });
    });
  }, [fenixCreds, partnerPackage, partnerPackageBatchShort]);

  function updateCustomersList(fenixCredsHere: any) {
    getPartnerUser(fenixCredsHere.tokenAuth, fenixCredsHere.partnerId)
      .then((dataUser: any) => {
        const fenixPartnerUserData = {
          data: {
            firstName: dataUser.partnerPartnerUsers[0].firstName,
            lastName: dataUser.partnerPartnerUsers[0].lastName,
            personNumber: dataUser.personNumber,
            email: dataUser.partnerPartnerUsers[0].email,
            phoneNumber: dataUser.partnerPartnerUsers[0].phoneNumber,
            wantSummaryEmail: dataUser.partnerPartnerUsers[0].wantSummaryEmail,
            creds: fenixCredsHere,
          },
        };
        // saveToStorage('fnxPartnerUser',dataUser);
        setInStorage("fnxPartnerUser", fenixPartnerUserData);
        setPartnerUser(dataUser);
        setFenixPartnerUser(fenixPartnerUserData);
      })
      .catch((e: any) => console.log(e));

    getPartnerPackage(fenixCredsHere.tokenAuth, fenixCredsHere.partnerId)
      .then((dataPackages: any) => {
        setPartnerPackage(dataPackages);

        getPartnerPackageBatches(
          fenixCredsHere.tokenAuth,
          fenixCredsHere.partnerId
        ).then((data: any) => {
          setPartnerPackageBatchShort(data);
        });
        // })
        // .catch((e:any) => console.log(e));
      })
      .catch((e: any) => console.log(e));

    getPartnerUserCustomerSummary(
      fenixCredsHere.tokenAuth,
      fenixCredsHere.partnerId
    )
      .then((data: any) => {
        // setCustomerPackagesAvailable(data.availablePackageInvites);
        // setCustomersInvited(data);
        setPartnerDashboard(data);
      })
      .catch((e: any) => console.log(e));

    // getPartnerPackageBatches(fenixCredsHere.tokenAuth,fenixCredsHere.partnerId,)
    // .then((data:any) => {
    //   setPartnerPackage(data);
    // })
    // .catch((e:any) => console.log(e));

    getPartnerCustomers(fenixCredsHere.tokenAuth, fenixCredsHere.partnerId)
      .then((data) => {
        const customerData = data.map((d: any): Person => {
          return {
            id: d.id,
            index: d.id,
            firstName: d.firstName,
            lastName: d.lastName,
            email: d.email,
            personNumber: d.personNumber,
            phone: d.phoneNumber,
            reminder: false,
            status: d.status,
            statusText: d.statusText,
            checked: false,
            partnerPackageInvitations: d.partnerPackageInvitations,
            consentRequestedByPartner: d.consentRequestedByPartner,
            // dataSharingConsent: d.dataSharingConsentText,
            dataSharingConsent: d.dataSharingConsent,
          };
        });

        setData(customerData);
        setPartnerCustomers(data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    const fenixTokenAuth = getFromStorage("FenixATkn", null);
    let fenixCredsHere = {
      tokenAuth: "",
      partnerId: 0,
    };
    fenixCredsHere.tokenAuth = fenixTokenAuth;
    if (
      typeof decodedFenixToken === "object" &&
      decodedFenixToken !== null &&
      decodedFenixToken.hasOwnProperty("partnerRoles") !== null
    ) {
      const partnerRoles = JSON.parse(decodedFenixToken.partnerRoles);
      const partnerId = partnerRoles[0].partnerId;
      fenixCredsHere.partnerId = partnerId;
      setFenixCreds(fenixCredsHere);
      updateCustomersList(fenixCredsHere);
    }
  }, [decodedFenixToken]);

  const onModalClose = () => {
    setBookModal(false);
  };

  const onModalSubmit = (data: any) => {
    if (typeModal == "import") {
      postPartnerCustomers(fenixCreds.tokenAuth, fenixCreds.partnerId, data)
        .then((dataResponse) => {
          // Sentry.captureMessage(dataResponse, "debug");
          // const err = new Error({message: "", options: })
          // Sentry.withScope((scope) => {
          //   scope.setExtra("data", {
          //     api: "postPartnerCustomers",
          //     dataPPrequestToIDA: data,
          //     dataIDAresponse: dataResponse,
          //   });
          //   Sentry.captureException(new Error("postPartnerCustomer SUCCESS"));
          // });
          toast.success(
            data.length === 1
              ? "Din kund är tillagd"
              : "Dina kunder är tillagda",
            {
              style: {
                padding: "30px 45px",
                borderRadius: "5px",
                backgroundColor: "#9EBEA8",
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                textTransform: "uppercase",
                maxWidth: "unset",
              },
              icon: "✓",
            }
          );
          updateCustomersList(fenixCreds);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (typeModal == "invite") {
      postCreateInvitation(
        fenixCreds.tokenAuth,
        fenixCreds.partnerId,
        data.partnerPackageId,
        data
      )
        .then((dataResponse) => {
          toast.success(
            data.partnerCustomerIds.length > 1
              ? "Inbjudningar har skickats"
              : "Inbjudan har skickats",
            {
              style: {
                padding: "30px 45px",
                borderRadius: "5px",
                backgroundColor: "#9EBEA8",
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                textTransform: "uppercase",
                maxWidth: "unset",
              },
              icon: "✓",
            }
          );
          updateCustomersList(fenixCreds);
        })
        .catch((e) => {
          toast.error(
            "Något gick fel. Inbjudan kunde inte sparas, försök gärna igen eller kontakta oss om problemet kvarstår.",
            {
              style: {
                padding: "30px 55px",
                borderRadius: "5px",
                backgroundColor: "#EA3324",
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "Inter",
                textTransform: "uppercase",
                maxWidth: "unset",
              },
              icon: "✕",
            }
          );
          console.log(e);
        });
    }
    if (typeModal == "update") {
      let messageSubject = "";
      let messageBody =
        "Hej, \n\n" +
        "Det har inkommit en förfrågan om att ta del av kunddata. Förfrågan kommer från partner, " +
        fenixPartnerUser.data.firstName +
        " " +
        fenixPartnerUser.data.lastName +
        " som önskar ta del av information om Tillgångar och skulder för " +
        data[0].firstName +
        " " +
        data[0].lastName +
        " " +
        data[0].personNumber +
        ".\n" +
        " \n\nHa en fin dag!";

      let dataEmail = {
        subject: "Ny förfrågan: ta del av kunddata.",
        message: messageBody,
      };

      if (data[0].share === true) {
        postSendEmail(
          fenixPartnerUser.data.creds.tokenAuth,
          fenixPartnerUser.data.creds.partnerId,
          dataEmail
        )
          .then((dataEmail: any) => {
            postPartnerCustomerEvent(
              fenixPartnerUser.data.creds.tokenAuth,
              fenixPartnerUser.data.creds.partnerId,
              data[0].id
            )
              .then((responseData: any) => {})
              .catch((e: any) => {
                console.log(e);
              });

            toast.success(
              "Din förfrågan behandlas. \nVi återkommer med bekräftelse på mail.",
              {
                duration: 6000,
                style: {
                  padding: "30px 45px",
                  borderRadius: "5px",
                  backgroundColor: "#9EBEA8",
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  maxWidth: "unset",
                },
                icon: "✓",
              }
            );
          })
          .catch((e: any) => {
            toast.error(
              "Något gick fel. \nVänligen prova igen eller kontakta oss om problemet kvarstår.",
              {
                style: {
                  padding: "30px 55px",
                  borderRadius: "5px",
                  backgroundColor: "#EA3324",
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 600,
                  fontFamily: "Inter",
                  textTransform: "uppercase",
                  maxWidth: "unset",
                },
                icon: "✕",
              }
            );
            console.log(e);
          });
      } else if (data[0].share === false) {
        postPartnerUserRequestDataConsent(
          fenixPartnerUser.data.creds.tokenAuth,
          fenixPartnerUser.data.creds.partnerId,
          data[0].id
        )
          .then((responseData: any) => {
            toast.success(
              "Din kund har fått ett mail med förfrågan om att dela data.",
              {
                duration: 6000,
                style: {
                  padding: "30px 45px",
                  borderRadius: "5px",
                  backgroundColor: "#9EBEA8",
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  maxWidth: "unset",
                },
                icon: "✓",
              }
            );
          })
          .catch((e: any) => {
            toast.error(
              "Något gick fel. \nVänligen prova igen eller kontakta oss om problemet kvarstår.",
              {
                style: {
                  padding: "30px 55px",
                  borderRadius: "5px",
                  backgroundColor: "#EA3324",
                  color: "#fff",
                  fontSize: "20px",
                  fontWeight: 600,
                  fontFamily: "Inter",
                  textTransform: "uppercase",
                  maxWidth: "unset",
                },
                icon: "✕",
              }
            );
            console.log(e);
          });
      }
    }
    setBookModal(false);
  };

  function handleShowImport() {
    setBookModal(true);
    setTypeModal("import");
  }

  function handleShowUpdate(event: any, id: number) {
    const person = partnerCustomers.find((object: { id: number }) => {
      return object.id === id;
    });
    setBookModal(true);
    setTypeModal("update");

    setSelectedCustomer(person);
  }

  function handleShowInvite() {
    setBookModal(true);
    setTypeModal("invite");
  }

  function handleShowIAutomation() {
    setBookModal(true);
    setTypeModal("confirm");
  }

  function handleShowPackages() {
    setBookModal(true);
    setTypeModal("package");
  }

  const handleChange = (event: any) => {
    setSelected(event.target.value);
  };

  const rerender = useReducer(() => ({}), {})[1];

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const columns = useMemo<ColumnDef<Person, any>[]>(
    () => [
      {
        accessorFn: (row) =>
          `${row.firstName};${row.lastName};${row.personNumber};${row.phone};${row.email}`,
        id: "fullName",
        header: "Kunder",
        cell: (info) => (
          <div>
            <div className="table-line text-left font-inter text-xs font-medium lg:text-sm">
              <div className="font-inter first-letter:capitalize">
                {info.getValue().split(";")[0]}{" "}
                {info.getValue().split(";")[1] !== "null"
                  ? info.getValue().split(";")[1]
                  : ""}
              </div>
            </div>
            <div className="table-line pt-0 text-xs leading-4 lg:text-sm">
              <span className="flex flex-col text-xxs">
                {info.getValue().split(";")[2] !== "null"
                  ? info.getValue().split(";")[2]
                  : ""}
              </span>
              <span className="flex flex-col text-xxs">
                {info.getValue().split(";")[3] !== "null"
                  ? info.getValue().split(";")[3]
                  : ""}
              </span>
              <span className="flex flex-col text-xxs underline">
                {info.getValue().split(";")[4] !== "null"
                  ? info.getValue().split(";")[4]
                  : ""}
              </span>
            </div>
          </div>
        ),
        footer: (props) => props.column.id,
        filterFn: "includesString",
        // sortingFn: fuzzySort,
      },
      {
        accessorFn: (row) => {
          let packageText = "-";
          if (
            row.hasOwnProperty("partnerPackageInvitations") &&
            row.partnerPackageInvitations !== undefined &&
            row.partnerPackageInvitations.length > 0
          ) {
            // const partnerPackageInvitations =
            //   row.partnerPackageInvitations.sort(function (a: any, b: any) {
            //     return (
            //       new Date(a.invitationSentDateText) -
            //       new Date(b.invitationSentDateText)
            //     );
            //   });
            packageText =
              row.partnerPackageInvitations[
                row.partnerPackageInvitations.length - 1
              ].partnerPackageName;
            // +
            // "|" +
            // row.partnerPackageInvitations[
            //   row.partnerPackageInvitations.length - 1
            // ].invitationSentDateText;
          }

          return packageText;
        },
        accessorKey: "package",
        id: "package",
        header: "Aktiverat paket",
        footer: (props) => props.column.id,
        cell: (row) => (
          <div className="table-line flex flex-col text-left font-inter text-xs font-medium first-letter:capitalize lg:text-sm">
            {row.getValue()}{" "}
          </div>
        ),
        filterFn: "arrIncludes",
      },
      {
        accessorFn: (row) => {
          return `${row.statusText}`;
        },
        accessorKey: "statusText",
        id: "statusText",
        header: "Status",
        footer: (props) => props.column.id,
        cell: (row) => {
          return (
            <div className="table-line flex flex-col text-left font-inter text-xs font-medium first-letter:capitalize lg:text-sm">
              {row.getValue()} <span className="text-xxs">{}</span>
            </div>
          );
        },
        // filterFn: 'customFilterFn',
        filterFn: "arrIncludes",

        // filterFn: (row, id, filterValue) => {
        //   console.log("filterFn",row, id, filterValue);
        //   return true;
        //          row.getValue(id).startsWith(filterValue)
        // },
        // filter: multiSelectFilter,
      },
      {
        accessorFn: (row) => {
          //console.log("accessorFn", row);
          const thisDataSharingConsent = row.dataSharingConsent;
          const thisConsentRequestedByPartner = row.consentRequestedByPartner;
          if (thisDataSharingConsent == true) {
            return (
              <div>
                {getCheckIcon()}{" "}
                <span className="font-inter first-letter:capitalize">
                  Tillgång till kunddata
                </span>
              </div>
            );
          } else if (thisDataSharingConsent == false) {
            return (
              <div>
                {getWarningIcon()}{" "}
                <span className="font-inter first-letter:capitalize">
                  Ej tillgång till kunddata
                </span>
              </div>
            );
          } else if (thisDataSharingConsent == null && row.status == 1) {
            return (
              <div>
                <span className="text-base">-</span>
              </div>
            );
          } else if (thisDataSharingConsent == null && row.status == 2) {
            return (
              <div>
                <span className="text-base">-</span>
              </div>
            );
          }
          return (
            <div>
              <span className="text-base">-</span>
            </div>
          );
        },
        accessorKey: "shareText",
        id: "shareText",
        header: "Tillgänglig data",
        footer: (props) => props.column.id,
        cell: (row) => {
          return (
            <div>
              <div className="table-line flex gap-2 text-left font-inter text-xs font-medium lg:text-sm">
                {row.getValue()}
              </div>
              {/*<div className="table-line flex text-left font-inter text-sm font-medium gap-2 -ml-3">*/}
              {/*  {getWarningIcon("", "20", "20")} <span className="text-base">Tillgångar och skulder</span>*/}
              {/*</div>*/}
            </div>
          );
        },
        // filterFn: 'customFilterFn',
        filterFn: "arrIncludes",

        // filterFn: (row, id, filterValue) => {
        //   console.log("filterFn",row, id, filterValue);
        //   return true;
        //          row.getValue(id).startsWith(filterValue)
        // },
        // filter: multiSelectFilter,
      },
    ],
    []
  );

  function handleAddFilterChips(addChip: FilterChip) {
    let currentFilterChips = filterChips;
    let hasChip = false;
    currentFilterChips.map((oneChip: any) => {
      if (oneChip == addChip) {
        hasChip = true;
      }
    });
    if (!hasChip) {
      currentFilterChips.push(addChip);
    }
    let currentFilters = columnFilters;
    currentFilters.map((oneFilter: FilterChip, oneFilterPos: number) => {
      if (
        oneFilter.id == addChip.id &&
        Array.isArray(oneFilter.value) &&
        oneFilter.value.length > 0
      ) {
        let hasAlreadyValue = false;
        oneFilter.value.map((oneValue: any) => {
          if (oneValue == addChip.value) hasAlreadyValue = true;
        });
        if (!hasAlreadyValue)
          currentFilters[oneFilterPos] = {
            id: oneFilter.id,
            value: [...oneFilter.value, addChip.value],
          };
      }
    });
    if (currentFilters.length == 0) {
      currentFilters.push({ id: addChip.id, value: [addChip.value] });
    }
    // currentFilters.push(addChip);
    // setColumnFilters(currentFilters);
    setIsChildOpen(0);
    setIsOpen(false);
    setFilterChips(currentFilterChips);
    // setColumnFilters(currentFilters);
    setColumnFilters([
      ...columnFilters,
      { id: addChip.id, value: [addChip.value, "Utkast"] },
    ]);
  }

  function handleRemoveFilterChips(removeChip: FilterChip) {
    let currentFilterChips = filterChips;
    currentFilterChips = currentFilterChips.filter(
      (oneChip: any) => oneChip !== removeChip
    );
    setFilterChips(currentFilterChips);
  }

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 6,
    });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  // const [data, setData] = useState<Person[]>(() => makeData(50));
  const [data, setData] = useState<Person[]>([]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      multiSelector: multiSelectFilter,
      customFilterFn: (row, id, filterValue) => {
        let rowFiltered = false;
        // return true;
        if (columnFilters.length > 0) {
          columnFilters.map((oneFilter: any) => {
            if (
              Array.isArray(oneFilter.value) &&
              oneFilter.value.length > 0 &&
              id == oneFilter.id
            ) {
              oneFilter.value.map((oneValue: any) => {
                if (row.getValue(id) == oneValue) {
                  rowFiltered = true;
                } else {
                  rowFiltered = false;
                }
              });
            }
          });
        } else {
          rowFiltered = true;
        }
        return rowFiltered;
      },
    },
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "fullName") {
      if (table.getState().sorting[0]?.id !== "fullName") {
        table.setSorting([{ id: "fullName", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  const n = table.getPageCount();
  const buttonList = [...Array(n)].map((elementInArray, index) => {
    return n >= PAGINATION_ITEMS_VISIBLE &&
      (index < PAGINATION_ITEMS_LEFT ||
        index >= n - PAGINATION_ITEMS_RIGHT ||
        index === table.getState().pagination.pageIndex) ? (
      <button
        key={index}
        onClick={() => table.setPageIndex(index)}
        className={
          index === table.getState().pagination.pageIndex
            ? "custom-pagination mr-1 h-[25px] w-[25px] cursor-pointer rounded-full border border-transparent bg-[#0C2632] p-0 font-inter text-xxs font-medium text-white"
            : "custom-pagination mr-1 h-[25px] w-[25px] cursor-pointer rounded-full border border-transparent p-0 font-inter text-xxs font-medium"
        }
      >
        {index + 1}
      </button>
    ) : index == n - PAGINATION_ITEMS_RIGHT - 1 ||
      (table.getState().pagination.pageIndex > PAGINATION_ITEMS_LEFT &&
        index == table.getState().pagination.pageIndex - 1) ? (
      "... "
    ) : (
      <button
        key={index}
        onClick={() => table.setPageIndex(index)}
        className={
          index === table.getState().pagination.pageIndex
            ? "custom-pagination mr-1 h-[25px] w-[25px] cursor-pointer rounded-full border border-transparent bg-[#0C2632] p-0 font-inter text-xxs font-medium text-white"
            : "custom-pagination mr-1 h-[25px] w-[25px] cursor-pointer rounded-full border border-transparent p-0 font-inter text-xxs font-medium"
        }
      >
        {index + 1}
      </button>
    );
  });

  function checkAllAction(e: any) {
    handleShowIAutomation();
  }

  return (
    <Layout modalOpen={bookModal} mainClass="relative flex flex-grow flex-col bg-[#FFFEFB] bg-[center_top] bg-no-repeat">
      <Navigation
        username={decodedFenixToken ? decodedFenixToken.userName : ""}
      />
      <div className="mx-auto w-full max-w-internal">
        <div className="pb-30 relative z-10 box-border rounded-b border-x border-b border-t-0 border-solid border-light-grey bg-white px-3 pb-20 pt-10 lg:px-12 lg:pt-20">
          <div className="">
            <div className="layout-dashboard">
              <div className="layout-dashboard__title pb-7">
                <h1 className="font-pprightgothic text-2xl font-medium uppercase tracking-widestXl lg:text-4xl">
                  Din översikt
                </h1>
                <p className="text-lg font-light">
                  Här kan du se hur det går för dina kunder. I översikten har du
                  möjlighet att få en tydligare bild över vilka som har loggat
                  in och påbörjat frågorna eller hur många som har färdigställt
                  sina avtal. Längre ner hittar du en mer detaljerad vy över din
                  kundbearbetning där du kan importera kunder och skicka
                  inbjudningar.
                </p>
              </div>
              <div className="grid grid-cols-2 border-y border-light-grey pb-20 pt-14 lg:grid-cols-4">
                <div className="stat text-center">
                  <div className="">
                    <h2 className="font-inter text-4xl font-semibold text-light-grey lg:text-8xl">
                      {partnerDashboard.availablePackageInvites}
                    </h2>
                    <p className="caption mt-4 font-inter text-sm font-medium uppercase lg:text-base">
                      Köpta
                      <br />
                      paket
                    </p>
                  </div>
                </div>
                <div className="stat text-center">
                  <div className="">
                    <h2 className="font-inter text-4xl font-semibold text-light-grey lg:text-8xl">
                      {partnerDashboard.countOfInvitationsSent}
                    </h2>
                    <p className="caption mt-4 font-inter text-sm font-medium uppercase lg:text-base">
                      skickade
                      <br />
                      inbjudningar
                    </p>
                  </div>
                </div>
                <div className="stat text-center">
                  <div className="">
                    <h2 className="font-inter text-4xl font-semibold text-light-grey lg:text-8xl">
                      {partnerDashboard.countOfLoggedInInvitedCustomers}
                    </h2>
                    <p className="caption mt-4 font-inter text-sm font-medium uppercase lg:text-base">
                      Kunder med påbörjad
                      <br />
                      livsplanering
                    </p>
                  </div>
                </div>
                <div className="stat text-center">
                  <div className="">
                    <h2 className="font-inter text-4xl font-semibold text-light-grey lg:text-8xl">
                      {partnerDashboard.countOfContractCreatedCustomers}
                    </h2>
                    <p className="caption mt-4 font-inter text-sm font-medium uppercase lg:text-base">
                      Kunder med aktiv
                      <br />
                      livsplanering
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between pb-2 pt-2">
                <h4 className="font-inter text-base font-medium uppercase tracking-widestXl">
                  kundbearbetning
                </h4>
              </div>
              <div className="relative">
                <div className="mb-4">
                  <div className="relative inline-flex">
                    {!isFocused && globalFilter.length < 1 ? (
                      <svg
                        className="absolute left-0 top-1/4"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5 13.5L18.25 18.25M15.25 8.5C15.25 12.2279 12.2279 15.25 8.5 15.25C4.77208 15.25 1.75 12.2279 1.75 8.5C1.75 4.77208 4.77208 1.75 8.5 1.75C12.2279 1.75 15.25 4.77208 15.25 8.5Z"
                          stroke="#0C2632"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                    <DebouncedInput
                      value={globalFilter ?? ""}
                      placeholder=""
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      onChange={(value) => setGlobalFilter(String(value))}
                      className="font-lg border-block z-1 border-0 border-b bg-transparent p-2 shadow shadow-transparent focus:outline-none"
                    />
                    {isFocused && (
                      <svg
                        className="absolute right-0 top-1/4 z-20 cursor-pointer"
                        fill="none"
                        height="20"
                        viewBox="0 0 20 20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setGlobalFilter("")}
                      >
                        <path
                          d="m14.25 5.75-8.5 8.5m0-8.5 8.5 8.5"
                          stroke="#0c2632"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </svg>
                    )}
                  </div>
                  {false && (
                    <div className="filter-component absolute -top-7 right-0 w-full max-w-[225px]">
                      <img
                        src="/images/icon-filter.svg"
                        alt="icon filter"
                        width={20}
                        height={20}
                        className=" ml-auto mr-0 cursor-pointer"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div className="filter-list relative -right-7 mt-2 rounded border border-[#E8E3D2] bg-white shadow-[0_2px_15px_0px_rgba(232,227,210,0.1)]">
                          {table.getHeaderGroups().map((headerGroup) => (
                            <div
                              key={headerGroup.id}
                              className="relative flex flex-col bg-white"
                            >
                              {headerGroup.headers.map((header, numbero) => {
                                if (
                                  filterHeaders.includes(
                                    String(header.column.columnDef.header)
                                  )
                                ) {
                                  return (
                                    <button
                                      key={header.id}
                                      onMouseEnter={() =>
                                        setIsChildOpen(numbero + 1)
                                      }
                                      // onMouseLeave={() => setIsChildOpen(false)}
                                      {...{
                                        className:
                                          "cursor-pointer capitalize text-sm py-2.5 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0",
                                      }}
                                    >
                                      {header.isPlaceholder ? null : (
                                        <>
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M7.20748 4.23214C7.49349 3.93252 7.96823 3.92148 8.26786 4.20748L13.7679 9.45748C13.9161 9.59901 14 9.79504 14 10C14 10.205 13.9161 10.401 13.7679 10.5425L8.26786 15.7925C7.96823 16.0785 7.49349 16.0675 7.20748 15.7679C6.92148 15.4682 6.93252 14.9935 7.23214 14.7075L12.1638 10L7.23214 5.29252C6.93252 5.00651 6.92148 4.53177 7.20748 4.23214Z"
                                              fill="#0C2632"
                                            />
                                          </svg>
                                        </>
                                      )}
                                    </button>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                              <div>
                                <button
                                  onClick={() => handleShowIAutomation()}
                                  className="w-6/12 cursor-pointer rounded-bl border border-deep-blue pb-5 pt-5 text-sm uppercase"
                                >
                                  Nollställ
                                </button>
                                <button className="r w-6/12 cursor-pointer rounded-br border border-deep-blue bg-deep-blue pb-5 pt-5 text-sm uppercase text-white hover:border-deep-blue/80  hover:bg-deep-blue/80">
                                  Filtrera
                                </button>
                              </div>
                            </div>
                          ))}
                          {isChildOpen == 2 && (
                            <div
                              className="absolute -right-44 top-0 mr-1 w-40"
                              onMouseLeave={() => setIsChildOpen(0)}
                            >
                              <div className="filter-sublist rounded border border-[#E8E3D2] bg-white">
                                <div className="relative z-10 flex flex-col bg-white">
                                  <div className="flex flex-col pl-3 pt-2">
                                    {partnerPackage.map((pack: any, index) => (
                                      <div className="flex items-center pb-2 pt-2">
                                        <Checkbox
                                          id={"checkbox-package-" + index}
                                          title={pack?.name}
                                          checkboxClass={"small"}
                                          index={index}
                                          isChecked={pack?.checked}
                                          checkHandler={() =>
                                            updateCheckPackages(index)
                                          }
                                        />
                                        <label
                                          htmlFor={"checkbox-package-" + index}
                                          className="mb-0 ml-2"
                                        >
                                          {pack.name} {pack.id}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: "package", value: "Packet 1"})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Packet*/}
                                  {/*  1*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: "package", value: "Packet 2"})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Packet*/}
                                  {/*  2*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: "package", value: "Packet 3"})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Packet*/}
                                  {/*  3*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: "package", value: "Packet 4"})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Packet*/}
                                  {/*  4*/}
                                  {/*</button>*/}
                                </div>
                              </div>
                            </div>
                          )}
                          {isChildOpen == 3 && (
                            <div
                              className="absolute -right-48 top-9 mr-1 w-44"
                              onMouseLeave={() => setIsChildOpen(0)}
                            >
                              <div className="filter-sublist rounded border border-[#E8E3D2] bg-white">
                                <div className="relative z-10 flex flex-col bg-white pl-3 pt-2">
                                  {statuses.map((stat, index) => (
                                    <div className="flex items-center pb-2 pt-2">
                                      <Checkbox
                                        id={"checkbox-" + index}
                                        title={stat.label}
                                        checkboxClass={"small"}
                                        index={index}
                                        isChecked={stat.checked}
                                        checkHandler={() =>
                                          updateCheckStatuses(index)
                                        }
                                      />
                                      <label
                                        className="mb-0 ml-2"
                                        htmlFor="auto"
                                      >
                                        {stat.label}
                                      </label>
                                    </div>
                                  ))}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: 'status', value: 'Utkast'})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Utkast*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: 'status', value: 'Färdig'})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Färdig*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: 'status', value: 'I process'})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">I*/}
                                  {/*  process*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: 'status', value: 'Inbjuden'})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Inbjuden*/}
                                  {/*</button>*/}
                                </div>
                              </div>
                            </div>
                          )}
                          {isChildOpen == 4 && (
                            <div
                              className="absolute -right-44 top-16 mr-1 w-40"
                              onMouseLeave={() => setIsChildOpen(0)}
                            >
                              <div className="filter-sublist rounded border border-[#E8E3D2] bg-white">
                                <div className="relative z-10 flex flex-col bg-white pl-3 pt-2">
                                  {automations.map((aut, index) => (
                                    <div className="flex items-center pb-2 pt-2">
                                      <Checkbox
                                        id={"checkbox-automation-" + index}
                                        title={aut.label}
                                        checkboxClass={"small"}
                                        index={index}
                                        isChecked={aut.checked}
                                        checkHandler={() =>
                                          updateCheckAutomation(index)
                                        }
                                      />
                                      <label
                                        htmlFor={"checkbox-automation-" + index}
                                        className="mb-0 ml-2"
                                      >
                                        {aut.label}
                                      </label>
                                    </div>
                                  ))}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: 'reminder', value: true})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Automatiserad*/}
                                  {/*</button>*/}
                                  {/*<button*/}
                                  {/*  onClick={() => handleAddFilterChips({id: 'reminder', value: false})}*/}
                                  {/*  className="cursor-pointer capitalize text-sm pb-2 pt-2 px-4 text-left font-normal flex justify-between items-center shrink filter-button outline-0">Inte*/}
                                  {/*  automatiserad*/}
                                  {/*</button>*/}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="selected-filter min-h-[48px]">
                  {filterChips.length > 0 &&
                    filterChips.map((oneChip: FilterChip) => (
                      <button className="font mb-3 mr-2 rounded-3xl bg-[#0C2632] pb-2 pl-3 pt-2 font-inter text-sm font-semibold text-white">
                        <span className="">
                          {oneChip.value == true
                            ? "Påminnelse automatiserad"
                            : oneChip.value == false
                            ? "Påminnelse inte automatiserad"
                            : oneChip.value}
                        </span>
                        <span
                          className="px-3 py-2"
                          onClick={() => handleRemoveFilterChips(oneChip)}
                        >
                          ✕
                        </span>
                      </button>
                    ))}
                </div>
                <div className=" overflow-x-scroll">
                  <table className="w-full table-fixed border-t lg:table-auto">
                    <thead>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="pt-2">
                          {headerGroup.headers.map((header) => {
                            return (
                              <th
                                key={header.id}
                                className="pl-2 pr-3 pt-3 lg:pl-0"
                                colSpan={header.colSpan}
                              >
                                {header.isPlaceholder ? null : (
                                  <>
                                    <div
                                      {...{
                                        className: header.column.getCanSort()
                                          ? "cursor-pointer table-head text-sm pb-3 text-left font-semibold flex gap-3"
                                          : "",
                                        onClick:
                                          header.column.getToggleSortingHandler(),
                                      }}
                                    >
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                      {header.id == "actions" && (
                                        <div className="flex items-center">
                                          <Checkbox
                                            id={"checkbox-checkall-status"}
                                            title={""}
                                            checkboxClass={"small"}
                                            index={0}
                                            isChecked={false}
                                            checkHandler={checkAllAction}
                                          />
                                          <label
                                            className="mb-0 ml-2"
                                            htmlFor="auto"
                                          >
                                            Aktivera för alla
                                          </label>
                                        </div>
                                      )}
                                      <span>
                                        {header.column.getIsSorted() === "desc"
                                          ? getSortingIcon()
                                          : header.column.getIsSorted() ===
                                            "asc"
                                          ? getSortingIcon("rotate-180")
                                          : ""}
                                      </span>
                                    </div>
                                    {/* {header.column.getCanFilter() ? (
                                  <div className="pb-3">
                                    <Filter column={header.column} table={table}/>
                                  </div>
                                ) : null} */}
                                  </>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => {
                        return (
                          <tr
                            key={row.id}
                            className="table-row cursor-pointer"
                            onClick={(event) =>
                              handleShowUpdate(event, row.original.id)
                            }
                          >
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className="overflow-hidden py-3 pl-2 pr-3 align-top lg:pl-0"
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="mt-7" />
                <div className="flex items-center justify-center gap-2">
                  <div>
                    <button
                      className="custom-pagination mr-1 h-[25px] w-[25px] cursor-pointer rounded-full border border-transparent p-0 font-inter text-xxs font-medium"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      {"<"}
                    </button>
                    {buttonList}
                    <button
                      className="custom-pagination mr-1 h-[25px] w-[25px] cursor-pointer rounded-full border border-transparent p-0 font-inter text-xxs font-medium"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      {">"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-12 flex flex-col justify-between">
                <button
                  id="bjud_in_kunder"
                  onClick={() => handleShowInvite()}
                  className="mt-12 flex items-center self-center rounded bg-deep-blue px-4 py-2.5 font-inter font-medium uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80 lg:self-end"
                >
                  bjud in kunder
                  <img
                    src="/images/plus.svg"
                    alt="icon plus"
                    width={12}
                    height={12}
                    className="ml-2"
                  />
                </button>
                <div
                  id="importera_kund"
                  onClick={() => handleShowImport()}
                  className="mt-3 flex cursor-pointer justify-center self-center px-8 font-inter text-sm font-medium uppercase tracking-widest underline lg:self-end"
                >
                  importera kund
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {bookModal && (
        <Modal
          data={{
            customers: partnerCustomers,
            packages: partnerPackage,
            batches: partnerPackageBatch.filter(
              (value: any, index: number, array: any) =>
                array.indexOf(value) === index
            ),
            customer: selectedCustomer,
          }}
          modal={typeModal}
          onClose={onModalClose}
          onSubmit={onModalSubmit}
        ></Modal>
      )}

      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
    </Layout>
  );
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );
  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-24 rounded border shadow"
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-24 rounded border shadow"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      {column.id !== "fullName" && (
        <datalist id={column.id + "list"}>
          {sortedUniqueValues.slice(0, 5000).map((value: any) => (
            <option value={value} key={value} />
          ))}
        </datalist>
      )}
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="table-search-input w-full border-b font-inter text-base font-normal placeholder:font-inter placeholder:text-base placeholder:font-normal"
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
