import React, {useState, useRef, useLayoutEffect} from "react";
import {Transition} from 'react-transition-group';
import {getPhoneNumber} from "../services/utils";

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  opacity: 0,
};

const transitionStyles: { [id: string]: React.CSSProperties } = {
  entering: {height: 0, opacity: 0},
  entered: {height: "72px", opacity: 1},
  exiting: {height: "72x", opacity: 1},
  exited: {height: 0, opacity: 0},
};

export default function ClickReveal() {
  const [show, setShow] = useState(false);
  const [phone, phoneFull, replacePhoneCTM] = getPhoneNumber();
  const nodeRef = useRef(null);
  const [secondaryColor, setSecondaryColor] = useState("#fff");

  useLayoutEffect(() => {
    if (show) {
      replacePhoneCTM();
    }
  }, [show]);

  const getPhoneIcon = (secondaryColor: any) => {
    return (
      <svg
        width="22"
        height="24"
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5689 15.5614C15.8569 14.8027 14.968 14.8027 14.2606 15.5614C13.7209 16.139 13.1812 16.7166 12.6506 17.304C12.5055 17.4655 12.383 17.4998 12.2062 17.3921C11.857 17.1865 11.4851 17.0201 11.1495 16.7949C9.58487 15.7327 8.27422 14.367 7.11324 12.83C6.53728 12.0664 6.02481 11.249 5.66654 10.3287C5.59398 10.1427 5.60758 10.0203 5.74817 9.86858C6.28785 9.30567 6.81392 8.72806 7.34453 8.15046C8.08375 7.34769 8.08375 6.40786 7.33999 5.6002C6.91823 5.14007 6.49646 4.68974 6.0747 4.22962C5.63933 3.7597 5.20849 3.28489 4.76859 2.81987C4.05657 2.07095 3.16769 2.07095 2.46022 2.82477C1.916 3.40237 1.39447 3.99466 0.841184 4.56247C0.328716 5.08623 0.0702154 5.72747 0.0157941 6.47639C-0.070373 7.69523 0.206269 8.84554 0.596288 9.96648C1.39447 12.2867 2.60988 14.3475 4.08379 16.2369C6.0747 18.7921 8.45109 20.8137 11.2311 22.2724C12.4828 22.9283 13.7798 23.4325 15.1903 23.5157C16.1608 23.5744 17.0043 23.3101 17.68 22.4926C18.1426 21.9346 18.6642 21.4255 19.1539 20.892C19.8796 20.099 19.8841 19.1396 19.163 18.3564C18.3013 17.4215 17.4351 16.4914 16.5689 15.5614Z"
          fill={secondaryColor}
        />
        <path
          d="M15.7022 11.6601L17.3756 11.3517C17.1126 9.6923 16.387 8.18955 15.285 6.99519C14.1194 5.73719 12.6455 4.94421 11.022 4.69946L10.7861 6.51549C12.0424 6.70639 13.1852 7.31825 14.0877 8.29235C14.9403 9.2126 15.4981 10.3776 15.7022 11.6601Z"
          fill={secondaryColor}
        />
        <path
          d="M18.3196 3.80826C16.3877 1.72302 13.9432 0.40628 11.2449 0L11.009 1.81602C13.3401 2.16846 15.4534 3.30898 17.1224 5.10542C18.7051 6.81375 19.7436 8.97242 20.1201 11.3465L21.7935 11.0381C21.3536 8.28713 20.1518 5.79071 18.3196 3.80826Z"
          fill={secondaryColor}
        />
      </svg>
    );
  };

  return (
    <div>
      <button
        id="ring_oss"
        onClick={() => setShow(!show)}
        className="mt-7 font-inter uppercase text-right font-semibold text-white bg-deep-blue hover:bg-deep-blue/80 py-5 px-8 leading-8 rounded flex items-center tracking-widest cursor-pointer max-w-[260px] w-full justify-center">
        <div className="mr-2.5">{getPhoneIcon("#fff")}</div>Ring oss</button>
      <Transition nodeRef={nodeRef} in={show} timeout={0}>
        {(state) => (
          <div
            ref={nodeRef}
            className="absolute flex items-center px-14 overflow-hidden rounded-b-[5px] bg-saturate-yellow font-inter uppercase text-right font-semibold tracking-widest cursor-pointer text-[#003343] max-w-[260px] w-full"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="flex cursor-pointer justify-center items-center">
              <div className="mr-2.5">{getPhoneIcon("#0C2632")}</div>
              <div>
                <a className="underline" href={`tel:+46105201626`}>
                  010-5201626
                </a>
              </div>
            </div>

          </div>
        )}
      </Transition>
    </div>
  );
}