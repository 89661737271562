import * as Sentry from "@sentry/react";
import React, {useContext, useEffect, useState} from "react";
import Layout from "../components/Layout";
import LoginModule from "../components/LoginModule";
import {removeFromStorage, setInStorage, getFromStorage} from "../services/storage";
import HomeModule from "../components/HomeModule";
import {useSearchParams} from "react-router-dom";
import {GlobalState} from "../context/GlobalState";

export default function LoginPage() {
  const {fenixPartnerUser, setFenixPartnerUser} = useContext(GlobalState);
  const [fenixToken, setFenixToken] = useState();
  const [loginSection, setLoginSection] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isLogoutNeeded = searchParams.get("logout");
  const [loginStatus, setLoginStatus] = useState("init");

  const updateLoginStatus = (dataFromChild:any) => {
    setLoginStatus(dataFromChild);
  };

  useEffect(() => {
    if (isLogoutNeeded === "true") {
      setFenixPartnerUser({
        data: {
          firstName: "",
          lastName: "",
          personNumber: "",
          email: "",
          phoneNumber: "",
          wantSummaryEmail: false,
          creds: {
            tokenAuth: "",
            partnerId: 0,
          },
        },
      });
      removeFromStorage("FenixATkn");
      removeFromStorage("FenixRTkn");
      setFenixToken(getFromStorage("FenixATkn", null));
    }
  }, [isLogoutNeeded]);
  useEffect(() => {
    if (
      fenixPartnerUser.data.firstName == "" &&
      fenixPartnerUser.data.lastName == ""
    ) {
      const storageFenixPartnerUser = getFromStorage(
        "fnxPartnerUser",
        fenixPartnerUser
      );
      setFenixPartnerUser(storageFenixPartnerUser);
    }
  }, [fenixPartnerUser]);
  useEffect(() => {
    document.body.classList.add("home");
    setFenixToken(getFromStorage("FenixATkn", null));

    return () => {

    };
  }, []);

  return (
    <Layout
      logoColor="#fff"
      themeColor="white"
      token={fenixToken}
      status={loginStatus}
      showLogin={setLoginSection}
      mainClass={`relative min-h-screen bg-[center_top] bg-no-repeat pb-10 bg-[#FFFEFB]`}
      containerClass={`container relative mx-auto flex flex-grow flex-col rounded-2xl bg-[#FFFEFB] px-3 pb-10 ${loginSection ? "bg-[#FFFEFB] pt-20" : "bg-transparent"}`}
    >
      {!loginSection && <HomeModule showLogin={setLoginSection} log={isLogoutNeeded} token={fenixToken}/>}
      {loginSection && <LoginModule onUpdateLoginStatus={updateLoginStatus}/>}
    </Layout>
  );
}
