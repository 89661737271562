import React, {PropsWithChildren, useEffect, useRef} from "react";
import UpdateModalBody from "./update-modal-body";

type Props = PropsWithChildren<{
  data?: any;
  modal: string;
  onClose: () => void;
  onSubmit: (data: any) => void;
}>;

const ModalUpdate = ({data, modal, onClose, onSubmit}: Props) => {

  useEffect(() => {
    const closeOnEscapeKey = (e:any) => e.key === "Escape" ? onClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);


  return (
    <div
      className="fixed z-50 flex h-full w-full flex-col top-0"
      style={{ backgroundColor: "rgba(0, 0, 0, .75)" }}
      onClick={() => {
        // close modal when outside of modal is clicked
        onClose();
      }}
    >
      <div className="flex max-h-[94%] w-[90%] lg:w-full max-w-widest justify-center mx-auto my-auto"
           onClick={e => {
             e.stopPropagation();
           }}>
        <div className="relative w-full flex flex-col rounded bg-[#FFFEFB] drop-shadow-xl px-3 lg:px-0">
          <div className="absolute right-2 top-2">
            <img
              src="/images/icon-close-dark.svg"
              alt="close"
              width="18"
              height="18"
              className="box-content h-fit max-w-none cursor-pointer p-4"
              onClick={onClose}
            />
          </div>
          <div className="overflow-auto no-scrollbar">
            <UpdateModalBody data={data} modal={modal} onClose={onClose} onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUpdate;
