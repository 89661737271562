interface CheckboxProps {
  onColor: string;
  isOn: boolean;
  handleToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Switch({isOn, handleToggle, onColor}: CheckboxProps) {

  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`switch-new`}
        type="checkbox"
      />
      <label
        style={{background: isOn ? "#fff" : "#f9f8f6", border: isOn ? "1px solid #273F5C" : "1px solid #736F62"}}
        className="switch-label"
        htmlFor={`switch-new`}
      >
        <span
          className={`switch-button`}
          style={{background: isOn ? "#273F5C" : "#736F62"}}/>
      </label>
    </>
  );
};
