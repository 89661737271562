import React from "react";

interface TabNavItem {
  id?: string;
  title: string;
  activeTab: string;
  setActiveTab: any;
  classItem: string;
  iconItem: string;
  born: string;
}

export default function TabNavItem({id, title, activeTab, setActiveTab, classItem, iconItem}: TabNavItem) {

  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <li onClick={handleClick}
        className={activeTab === id ? classItem + " active-tab text-deepblue after:!bg-[#0C2632] " : classItem}>
      {iconItem && <img src={iconItem} width={46} height={46} alt="user icon" className="mr-1"/>}
      <span className="font-semibold font-inter">{title}</span>
    </li>
  );
};