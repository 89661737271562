import React from "react";

export const getFenixLogo = (
  color = "#002733",
  width = "159",
  height = "50"
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 159 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_984_1950)">
        <path
          d="M0.968964 49.654V37.3409H8.1652V38.3451H2.1085V42.9799H7.60324V43.9996H2.1085V49.654H0.968964Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.578712 36.9547H8.55545V38.7314H2.49875V42.5937H7.99349V44.3858H2.49875V50.0403H0.578712V36.9547ZM1.35922 37.7272V49.2678H1.71825V43.6134H7.21299V43.3662H1.71825V37.9589H7.77495V37.7272H1.35922Z"
          fill={color}
        />
        <path
          d="M15.2834 49.654V37.3409H22.4952V38.3451H16.4073V42.9799H22.105V43.9996H16.4073V48.6498H22.6201V49.654H15.2834Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8931 36.9547H22.8855V38.7314H16.7976V42.5937H22.4952V44.3858H16.7976V48.2636H23.0104V50.0403H14.8931V36.9547ZM15.6736 37.7272V49.2678H22.2299V49.0361H16.0171V43.6134H21.7147V43.3662H16.0171V37.9589H22.105V37.7272H15.6736Z"
          fill={color}
        />
        <path
          d="M39.4009 37.3409V49.654H38.2926L30.9403 39.3493H30.8466V49.654H29.7071V37.3409H30.7998L38.1834 47.6611H38.277V37.3409H39.4009Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.3168 36.9547H31.0016L37.8868 46.5782V36.9547H39.7912V50.0403H38.0905L31.2369 40.4346V50.0403H29.3168V36.9547ZM30.0973 37.7272V49.2678H30.4564V38.9631H31.1424L38.4948 49.2678H39.0107V37.7272H38.6673V48.0473H37.9815L30.5979 37.7272H30.0973Z"
          fill={color}
        />
        <path
          d="M48.1114 37.3409H46.9718V49.654H48.1114V37.3409Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5816 36.9547H48.5016V50.0403H46.5816V36.9547ZM47.3621 37.7272V49.2678H47.7211V37.7272H47.3621Z"
          fill={color}
        />
        <path
          d="M55.8852 37.3409L59.335 42.6864H59.413L62.8629 37.3409H64.2053L60.1935 43.4898L64.2053 49.654H62.8629L59.413 44.3704H59.335L55.8852 49.654H54.5427L58.6169 43.4898L54.5427 37.3409H55.8852Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.8201 36.9547H56.099L59.374 42.0294L62.6491 36.9547H64.9219L60.6579 43.49L64.9209 50.0403H62.6504L59.374 45.0222L56.0976 50.0403H53.8211L58.1504 43.49L53.8201 36.9547ZM55.2653 37.7272L59.0834 43.4895L55.2643 49.2678H55.6727L59.1225 43.9841H59.6255L63.0753 49.2678H63.4898L59.7292 43.4895L63.4888 37.7272H63.0767L59.6268 43.0726H59.1212L55.6714 37.7272H55.2653Z"
          fill={color}
        />
        <path
          d="M77.1461 49.654V37.3409H84.3423V38.3451H78.27V42.9799H83.7803V43.9996H78.27V49.654H77.1461Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.7558 36.9547H84.7326V38.7314H78.6603V42.5937H84.1706V44.3858H78.6603V50.0403H76.7558V36.9547ZM77.5363 37.7272V49.2678H77.8798V43.6134H83.3901V43.3662H77.8798V37.9589H83.9521V37.7272H77.5363Z"
          fill={color}
        />
        <path
          d="M91.4605 49.654H90.2741L94.8011 37.3409H96.0186L100.546 49.654H99.3592L95.4567 38.8086H95.363L91.4605 49.654Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.5278 36.9547H96.2919L101.103 50.0403H99.0839L95.4099 39.8298L91.7358 50.0403H89.7169L94.5278 36.9547ZM95.0743 37.7272L90.8314 49.2678H91.1852L95.0877 38.4224H95.732L99.6345 49.2678H99.9883L95.7454 37.7272H95.0743Z"
          fill={color}
        />
        <path
          d="M98.485 44.9265H92.3191V45.9307H98.485V44.9265Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.9288 44.5403H98.8753V46.317H91.9288V44.5403ZM92.7093 45.3128V45.5445H98.0948V45.3128H92.7093Z"
          fill={color}
        />
        <path
          d="M106.946 37.3409H108.257L112.784 48.0628H112.893L117.404 37.3409H118.716V49.654H117.639V39.7201H117.545L113.346 49.654H112.316L108.116 39.7201H108.023V49.654H106.946V37.3409Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.555 36.9547H108.517L112.838 47.1892L117.144 36.9547H119.106V50.0403H117.248V41.4226L113.606 50.0403H112.056L108.413 41.4226V50.0403H106.555V36.9547ZM107.336 37.7272V49.2678H107.633V39.3339H108.376L112.575 49.2678H113.086L117.285 39.3339H118.029V49.2678H118.325V37.7272H117.665L113.153 48.449H112.524L107.997 37.7272H107.336Z"
          fill={color}
        />
        <path
          d="M127.442 37.3409H126.302V49.654H127.442V37.3409Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.912 36.9547H127.832V50.0403H125.912V36.9547ZM126.692 37.7272V49.2678H127.051V37.7272H126.692Z"
          fill={color}
        />
        <path
          d="M135.013 49.654V37.3409H136.152V48.6498H142.084V49.654H135.013Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M134.622 36.9547H136.542V48.2636H142.474V50.0403H134.622V36.9547ZM135.403 37.7272V49.2678H141.694V49.0361H135.762V37.7272H135.403Z"
          fill={color}
        />
        <path
          d="M147.75 37.3409H149.062L152.746 43.1808H152.855L156.539 37.3409H157.85L153.37 44.324V49.654H152.23V44.324L147.75 37.3409Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.04 36.9547H149.278L152.8 42.5381L156.322 36.9547H158.56L153.76 44.4363V50.0403H151.84V44.4363L147.04 36.9547ZM148.46 37.7272L152.621 44.2118V49.2678H152.98V44.2118L157.14 37.7272H156.755L153.071 43.567H152.529L148.845 37.7272H148.46Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.3785 9.21635C93.1838 5.01258 88.7477 2.28798 84.3304 2.30194L84.3256 2.30195C76.6317 2.29482 71.9454 7.63894 67.7092 13.284C67.3472 13.7664 66.9872 14.2527 66.6281 14.7378C66.4555 14.971 66.2832 15.2038 66.1109 15.4358C66.3207 15.7101 66.5308 15.9861 66.7415 16.2628C67.1995 16.8642 67.6599 17.469 68.1239 18.0668C69.8261 20.2602 71.6164 22.4184 73.655 24.2465C74.1342 24.6761 74.1707 25.4089 73.7365 25.8831C73.3024 26.3573 72.562 26.3934 72.0828 25.9638C69.8833 23.9913 67.9896 21.6981 66.2669 19.4784C65.7695 18.8375 65.2895 18.207 64.8209 17.5917C64.764 17.5169 64.7072 17.4424 64.6506 17.368C63.6247 18.6894 62.5909 19.9213 61.5246 20.9408C59.3642 23.0065 56.8488 24.4195 53.7241 23.8995L53.7157 23.8981L53.7157 23.898C45.5092 22.471 43.3023 11.9099 50.7155 7.72252L50.7642 7.695L50.8154 7.67222C53.3594 6.53921 55.6505 6.56517 57.7531 7.45163C59.7871 8.30919 61.5483 9.93242 63.1828 11.7946C63.6625 12.3411 64.1422 12.9216 64.6231 13.5237C64.6462 13.4926 64.6692 13.4615 64.6923 13.4303C65.0624 12.9308 65.4406 12.4204 65.8294 11.9022C70.0301 6.30456 75.3492 -0.0226385 84.3254 -0.0154448C89.6791 -0.031376 94.8889 3.23038 97.4588 8.15254C100.068 13.1502 99.9225 19.764 94.7918 26.3531C94.3971 26.86 93.6619 26.9542 93.1498 26.5636C92.6376 26.173 92.5424 25.4454 92.9371 24.9385C97.5627 18.9983 97.5342 13.3453 95.3785 9.21635ZM63.1783 15.4555C62.5869 14.7021 62.0036 13.9848 61.4151 13.3144C59.8476 11.5286 58.3707 10.2309 56.8354 9.58364C55.3814 8.97062 53.7871 8.9035 51.8279 9.76269C46.499 12.8239 47.9528 20.5396 54.1167 21.6149C56.1884 21.9581 57.9881 21.1 59.8977 19.2742C61.0015 18.2187 62.068 16.9129 63.1783 15.4555Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_984_1950">
          <rect
            width="157.989"
            height="50.0403"
            fill="white"
            transform="translate(0.578712)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const getLogOutIcon = (
  color = "#FFFEFB",
  width = "16",
  height = "18"
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5C6 2.79086 7.79086 1 10 1C12.2091 1 14 2.79086 14 5V5.25C14 5.66421 13.6642 6 13.25 6C12.8358 6 12.5 5.66421 12.5 5.25V5C12.5 3.61929 11.3807 2.5 10 2.5C8.61929 2.5 7.5 3.61929 7.5 5V8H15.25C16.7688 8 18 9.23122 18 10.75V16.25C18 17.7688 16.7688 19 15.25 19H4.75C3.23122 19 2 17.7688 2 16.25V10.75C2 9.23122 3.23122 8 4.75 8H6V5ZM4.75 9.5C4.05964 9.5 3.5 10.0596 3.5 10.75V16.25C3.5 16.9404 4.05964 17.5 4.75 17.5H15.25C15.9404 17.5 16.5 16.9404 16.5 16.25V10.75C16.5 10.0596 15.9404 9.5 15.25 9.5H4.75ZM10 12C10.4142 12 10.75 12.3358 10.75 12.75V14.25C10.75 14.6642 10.4142 15 10 15C9.58579 15 9.25 14.6642 9.25 14.25V12.75C9.25 12.3358 9.58579 12 10 12Z"
        fill={color}
      />
    </svg>
  );
};

export const getLogInIcon = (
  color = "#FFFEFB",
  width = "16",
  height = "18"
) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 5C6 2.79086 7.79086 1 10 1C12.2091 1 14 2.79086 14 5V8H15.25C16.7688 8 18 9.23122 18 10.75V16.25C18 17.7688 16.7688 19 15.25 19H4.75C3.23122 19 2 17.7688 2 16.25V10.75C2 9.23122 3.23122 8 4.75 8H6V5ZM7.5 8H12.5V5C12.5 3.61929 11.3807 2.5 10 2.5C8.61929 2.5 7.5 3.61929 7.5 5V8ZM4.75 9.5C4.05964 9.5 3.5 10.0596 3.5 10.75V16.25C3.5 16.9404 4.05964 17.5 4.75 17.5H15.25C15.9404 17.5 16.5 16.9404 16.5 16.25V10.75C16.5 10.0596 15.9404 9.5 15.25 9.5H4.75ZM10 12C10.4142 12 10.75 12.3358 10.75 12.75V14.25C10.75 14.6642 10.4142 15 10 15C9.58579 15 9.25 14.6642 9.25 14.25V12.75C9.25 12.3358 9.58579 12 10 12Z"
        fill={color}
      />
    </svg>
  );
};

export const getBankIdIcon = (
  color = "#FFFEFB",
  width = "24",
  height = "23"
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 317 300">
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="BankID_logo_white"
          transform="translate(-151.000000, -107.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(151.600000, 107.500000)">
            <path
              d="M99.7,155.9 L112.9,72.7 C107.7,72.7 98.8,72.7 98.8,72.7 C92.2,72.7 83.7,69 81.2,62.2 C80.4,59.9 78.5,52 89.4,44.3 C93.3,41.6 95.8,38.6 96.3,36.3 C96.8,33.9 96.2,31.8 94.5,30.2 C92.1,27.9 87.4,26.6 81.4,26.6 C71.3,26.6 64.2,32.4 63.5,36.6 C63,39.7 65.4,42.2 67.5,43.8 C73.8,48.5 75.3,55.3 71.4,61.7 C67.4,68.3 58.7,72.6 49.4,72.7 C49.4,72.7 40.2,72.7 35,72.7 C33.8,80.8 14.2,205 12.7,214.8 L90.5,214.8 C91.2,210.5 94.8,187 99.7,155.9 L99.7,155.9 Z"
              id="Path"
            ></path>
            <g transform="translate(0.000000, 243.600000)">
              <path
                d="M8.5,0 L40.4,0 C54,0 57.3,6.9 56.3,13.2 C55.5,18.3 52,22.1 46,24.6 C53.6,27.5 56.6,32 55.5,39.1 C54.1,48 46.4,54.6 36.3,54.6 L2.84217094e-14,54.6 L8.5,0 Z M29.6,22.6 C35.8,22.6 38.7,19.3 39.3,15.4 C39.9,11.2 38,8.3 31.8,8.3 L26.3,8.3 L24.1,22.6 L29.6,22.6 Z M26.2,46.1 C32.6,46.1 36.3,43.5 37.2,38.2 C37.9,33.6 35.3,30.9 29.1,30.9 L22.9,30.9 L20.5,46.2 L26.2,46.2 L26.2,46.1 Z"
                id="Shape"
              ></path>
              <path
                d="M100.2,55 C91.9,55.6 87.9,54.7 85.9,51.1 C81.5,53.8 76.6,55.2 71.4,55.2 C62,55.2 58.7,50.3 59.6,44.9 C60,42.3 61.5,39.8 63.9,37.7 C69.1,33.2 81.9,32.6 86.9,29.2 C87.3,25.4 85.8,24 81.1,24 C75.6,24 71,25.8 63.1,31.2 L65,18.8 C71.8,13.9 78.4,11.6 86,11.6 C95.7,11.6 104.3,15.6 102.7,26.2 L100.8,38.2 C100.1,42.4 100.3,43.7 105,43.8 L100.2,55 Z M85.8,36.1 C81.4,38.9 73.2,38.4 72.3,44.2 C71.9,46.9 73.6,48.9 76.3,48.9 C78.9,48.9 82.1,47.8 84.7,46 C84.5,45 84.6,44 84.9,42.1 L85.8,36.1 Z"
                id="Shape"
              ></path>
              <path
                d="M115.7,12.3 L132.3,12.3 L131.4,17.8 C136.7,13.3 140.7,11.6 145.9,11.6 C155.2,11.6 159.5,17.3 158,26.6 L153.7,54.5 L137.1,54.5 L140.7,31.4 C141.4,27.2 140.1,25.2 136.9,25.2 C134.3,25.2 131.9,26.6 129.6,29.7 L125.8,54.4 L109.2,54.4 L115.7,12.3 Z"
                id="Path"
              ></path>
              <polygon
                id="Path"
                points="171 0 187.6 0 183.4 26.8 199.3 12.3 219.8 12.3 199.4 30.3 215.8 54.5 194.9 54.5 182.3 34.9 182.1 34.9 179.1 54.4 162.5 54.4"
              ></polygon>
            </g>
            <polygon
              id="Path"
              points="229.7 243.6 248.8 243.6 240.4 298.1 221.3 298.1"
            ></polygon>
            <path
              d="M258.1,243.6 L285.4,243.6 C306.5,243.6 312.6,258.9 310.6,271.6 C308.7,284 298.9,298.1 280.4,298.1 L249.6,298.1 L258.1,243.6 Z M275.8,285.1 C285.1,285.1 290.2,280.5 291.7,270.8 C292.8,263.6 290.6,256.5 280.3,256.5 L275.2,256.5 L270.8,285.1 L275.8,285.1 Z"
              id="Shape"
            ></path>
            <path
              d="M204.3,-2.84217094e-14 L124.8,-2.84217094e-14 L114.2,67.3 L127.7,67.3 C135.1,67.3 142.1,63.9 145.1,59 C146.1,57.4 146.5,56 146.5,54.7 C146.5,51.9 144.6,49.8 142.7,48.4 C137.5,44.5 136.4,40.4 136.4,37.5 C136.4,36.9 136.4,36.4 136.5,35.9 C137.6,28.8 147.2,21.1 159.9,21.1 C167.5,21.1 173.3,22.9 176.8,26.2 C179.9,29.1 181.1,33.2 180.2,37.5 C179.1,42.6 174,46.8 171.1,48.9 C163.4,54.3 164.4,59 164.9,60.4 C166.5,64.6 172.6,67.3 177.3,67.3 L197.9,67.3 C197.9,67.3 197.9,67.3 197.9,67.4 C225.9,67.6 240.9,80.5 236.2,110.5 C231.8,138.4 210.4,150.4 184.9,150.6 L174.8,215 L189.7,215 C252.6,215 304,174.6 314.1,110.8 C326.6,31.6 276.3,-2.84217094e-14 204.3,-2.84217094e-14 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};


export const getSortingIcon = (className = "", width = "20", height = "20") => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.75C1 4.33579 1.33579 4 1.75 4H18.25C18.6642 4 19 4.33579 19 4.75C19 5.16421 18.6642 5.5 18.25 5.5H1.75C1.33579 5.5 1 5.16421 1 4.75ZM4 10C4 9.58579 4.33579 9.25 4.75 9.25H15.25C15.6642 9.25 16 9.58579 16 10C16 10.4142 15.6642 10.75 15.25 10.75H4.75C4.33579 10.75 4 10.4142 4 10ZM7 15.25C7 14.8358 7.33579 14.5 7.75 14.5H12.25C12.6642 14.5 13 14.8358 13 15.25C13 15.6642 12.6642 16 12.25 16H7.75C7.33579 16 7 15.6642 7 15.25Z"
        fill="#0C2632"
      />
    </svg>
  );
};

export const getCheckIcon = (className = "", width = "20", height = "20") => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline-block", flexShrink: 0 }}
    >
      <path
        d="M4.6875 12.1875L10 17.8125L20.3125 7.1875"
        stroke="#A0C1AB"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const getWarningIcon = (className = "", width = "20", height = "20") => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: "inline-block", flexShrink: 0 }}
    >
      <path
        d="M12.5 11.5625V7.1875M12.5 17.8125C12.5 17.0803 12.5 16.6697 12.5 15.9375M13.989 21.8687L21.8687 13.989C22.3899 13.4678 22.6504 13.2072 22.7481 12.9067C22.834 12.6424 22.834 12.3576 22.7481 12.0933C22.6504 11.7928 22.3899 11.5322 21.8687 11.011L13.989 3.13133C13.4678 2.61015 13.2072 2.34955 12.9067 2.25191C12.6424 2.16603 12.3576 2.16603 12.0933 2.25191C11.7928 2.34955 11.5322 2.61015 11.011 3.13133L3.13133 11.011C2.61015 11.5322 2.34955 11.7928 2.25191 12.0933C2.16603 12.3576 2.16603 12.6424 2.25191 12.9067C2.34955 13.2072 2.61015 13.4678 3.13133 13.989L11.011 21.8687C11.5322 22.3899 11.7928 22.6504 12.0933 22.7481C12.3576 22.834 12.6424 22.834 12.9067 22.7481C13.2072 22.6504 13.4678 22.3899 13.989 21.8687Z"
        stroke="#D81000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const getPersonIcon = (className = "", width = "24", height = "24") => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9 6.00156C15.9 8.15547 14.1539 9.90156 12 9.90156C9.84609 9.90156 8.1 8.15547 8.1 6.00156C8.1 3.84765 9.84609 2.10156 12 2.10156C14.1539 2.10156 15.9 3.84765 15.9 6.00156Z"
        stroke="#0C2632"
        strokeWidth="1.5"
        strokeLinejoin="round"/>
      <path
        d="M4.5 19.9516C4.5 16.7207 7.11913 14.1016 10.35 14.1016H13.65C16.8809 14.1016 19.5 16.7207 19.5 19.9516C19.5 21.0285 18.627 21.9016 17.55 21.9016H6.45C5.37304 21.9016 4.5 21.0285 4.5 19.9516Z"
        stroke="#0C2632"
        strokeWidth="1.5"
        strokeLinejoin="round"/>
    </svg>
  );
};

export const getRightArrow = (strokeW="1.5") => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.25 10.5H3.75M16.25 10.5L12.25 6.25M16.25 10.5L12.25 14.75"
            stroke="#0C2632"
            strokeWidth={strokeW}
            strokeLinecap="round"
            strokeLinejoin="round"/>
    </svg>
  );
};
