import React, {PropsWithChildren} from "react";
import {useState} from "react";
import useCopyToClipboard from "../hooks/useCopyToClipboard";

const validateEmail = (email: any) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

type Props = PropsWithChildren<{
  onClose: () => void;
  onSubmit: (data: any[]) => void;
}>;

export default function ImportModalBody({onClose, onSubmit}: Props) {
  const [value, copy] = useCopyToClipboard();
  const [valueMassimport, setValueMassimport] = useState("");
  const [isEmail, setIsEmail] = useState([-1, 0]);
  const [arrayMassimport, setArrayMassimport] = useState([]);
  const [failMassImport, setFailMassImport] = useState([]);
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [showMassImport, setShowMassImport] = useState(false);
  const [showMassImportPreview, setShowMassImportPreview] = useState(false);

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [personnumber, setPersonnumber] = useState("");

  const [personnumberError, setPersonnumberError] = useState(
    "Ange personnummer med 12 siffror"
  );
  const [phoneError, setPhoneError] = useState(
    "Ange telefonnummer med eller utan landskod"
  );
  const [emailError, setEmailError] = useState("Fyll i kundens mailadress");
  const [nameError, setNameError] = useState("Fyll i kundens förnamn");
  const [surnameError, setSurnameError] = useState(
    "Fyll i kundens efternamn"
  );
  const [personnumberDirty, setPersonnumberDirty] = useState(false);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [nameDirty, setNameDirty] = useState(false);
  const [surnameDirty, setSurnameDirty] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [batchFields, setBatchFields] = useState<string[]>([]);

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const re = /^((([+]46)\s*((1|7)[0236]))|(0(1|7)[0236]))\s*(([-]|())\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*|([0-9]\s*([-]|()))\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*)$/;
  const letters = /^[a-zA-ZÄÖÅ åáãâäàéêëèíîïìóõôöòøúûüùçñ\-'"`’]+$/;
  const pnum = "(^\\d{12}$)|(^\\d{8}\\-\\d{4}$)"

  const displayNotice = () => {
    setNoticeVisible(true);

    setTimeout(() => {
      setNoticeVisible(false);
    }, 1000);
  };

  function handleMassImport() {
    setShowMassImport((showMassImport) => !showMassImport);
    setShow(false);
  }

  function handleMassImportPreview() {
    let arrayCustomers: any = [];
    let arrayFailedCustomers: any = [];
    let previewCustomers;
    let isEmail = [-1, 0];
    if (!showMassImportPreview && valueMassimport !== "") {
      let tempCustomers = valueMassimport.split(/\r?\n/);
      if (Array.isArray(tempCustomers) && tempCustomers.length > 0) {
        tempCustomers.map((oneLine, oneNumber) => {
          let hasEmail = false;
          const tempRecord = oneLine.split(/\t/);
          const notEmpty = oneLine.replace(/\t/g, "").charCodeAt(0);
          if (tempRecord.length > 0) {
            tempRecord.map((cell: any, numb: any) => {
              if (validateEmail(cell)) {
                hasEmail = true;
                isEmail = [numb, tempRecord.length];
              }
            });
            notEmpty && hasEmail && arrayCustomers.push(tempRecord);
            if (notEmpty && !hasEmail) {
              arrayFailedCustomers.push(tempRecord);
            }
          }
        });
      }
    }
    setShowMassImportPreview((showMassImportPreview) => !showMassImportPreview);
    if (arrayCustomers.length > 0) {
      setArrayMassimport(arrayCustomers);
      setIsEmail(isEmail);
    } else {
      setArrayMassimport([]);
      setIsEmail([-1, 0]);
    }
  }

  function changeTHeadBatchImport(e: any, num: number) {
    let newBatchFields: string[] = batchFields.length > 0 ? batchFields : [];
    newBatchFields[num] =
      e.target.value === "Förnamn"
        ? "firstName"
        : e.target.value === "Efternamn"
          ? "lastName"
          : e.target.value === "Personnummer"
            ? "personNumber"
            : e.target.value === "Telefonnummer"
              ? "phoneNumber"
              : e.target.value === "Mailadress"
                ? "email"
                : "";
    setBatchFields(newBatchFields);
  }

  function renderTHead() {
    let hCells = [];
    const cellFields = ["firstName", "lastName", "personNumber", "phoneNumber"];
    const cellSelect = (num: number) => (
      <select className="px-4" onChange={(e) => changeTHeadBatchImport(e, num)}>
        <option>Inte importerad</option>
        {cellFields.map((option: any, index) => (
          <option key={index}>
            {option === "firstName"
              ? "Förnamn"
              : option === "lastName"
                ? "Efternamn"
                : option === "personNumber"
                  ? "Personnummer"
                  : option === "phoneNumber"
                    ? "Telefonnummer"
                    : "Inte importerad"}
          </option>
        ))}
      </select>
    );
    for (let th = 0; th < isEmail[1]; th++) {
      hCells.push(
        <th key={th}>{th == isEmail[0] ? "Mailadress" : cellSelect(th)}</th>
      );
    }
    return (
      <thead>
      <tr>{hCells}</tr>
      </thead>
    );
  }

  function renderDataPreview() {
    return (
      <table
        cellPadding={5}
        className="w-full border-collapse border border-slate-500 block overflow-x-scroll"
      >
        {renderTHead()}
        <tbody>
        {arrayMassimport.map((row: any, index: number) => {
          return (
            <tr key={index}>
              {row.map((cell: any, index: number) => {
                return <td key={index}>{cell}</td>;
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
    );
  }

  function handleClick(e: any) {
    setShow((show) => !show);
  }

  function onSubmitClick(e: any) {
    e.preventDefault();
    let data = {
      firstName: "",
      lastName: "",
      personNumber: "",
      phoneNumber: "",
      email: "",
    };

    let canSubmit = false;

    if (name.length < 2 || !letters.test(String(name))) {
      setNameError("Fyll i kundens förnamn");
      setNameDirty(true);
    } else {
      setNameDirty(false);
      setNameError("");
    }

    if (surname.length < 2 || !letters.test(String(surname))) {
      setSurnameError("Fyll i kundens efternamn");
      setSurnameDirty(true);
    } else {
      setSurnameDirty(false);
      setSurnameError("");
    }

    if (!isValidEmail(email)) {
      setEmailError("Fyll i kundens mailadress");
      setEmailDirty(true);
    } else {
      setEmailError("");
      setEmailDirty(false);
    }

    if (!re.test(String(phone))) {
      setPhoneError(
        "Ange telefonnummer med eller utan landskod"
      );
      setPhoneDirty(true);
    } else {
      setPhoneDirty(false);
      setPhoneError("");
    }

    if (personnumber.match(pnum) === null) {
      setPersonnumberError("Ange personnummer med 12 siffror");
      setPersonnumberDirty(true);
    } else {
      setPersonnumberDirty(false);
      setPersonnumberError("");
    }

    if (isValidEmail(email) && re.test(String(phone)) && (name.length >= 2 && letters.test(String(name))) && (surname.length >= 2 && letters.test(String(surname))) && personnumber.match(pnum) !== null) {
      canSubmit = true;
    }
    if (canSubmit) {
      data.email = email;
      data.personNumber = personnumber;
      data.lastName = surname;
      data.firstName = name;
      data.phoneNumber = phone;
      onSubmit([data]);
      //      onClose();
    }
    return false;
  }

  function onSubmitBatchClick(e: any) {
    e.preventDefault();
    let dataBatch: any[] = [];
    let canSubmit = false;
    if (arrayMassimport.length > 0) {
      arrayMassimport.map((oneData: any, numb) => {
        const newData = {
          firstName:
            batchFields.indexOf("firstName") !== -1
              ? oneData[batchFields.indexOf("firstName")]
              : "",
          lastName:
            batchFields.indexOf("lastName") !== -1
              ? oneData[batchFields.indexOf("lastName")]
              : "",
          personNumber:
            batchFields.indexOf("personNumber") !== -1
              ? oneData[batchFields.indexOf("personNumber")]
              : "",
          phoneNumber:
            batchFields.indexOf("phoneNumber") !== -1
              ? oneData[batchFields.indexOf("phoneNumber")]
              : "",
          email: oneData[isEmail[0]],
        };
        dataBatch.push(newData);
      });
      Array.isArray(dataBatch) && onSubmit(dataBatch);
    }

    return false;
  }

  return (
    <>
      <div className="mx-auto max-w-import-info py-24">
        <div className="invite-full-screen-dialog advisor-dialog advisors">
          <div className="">
            {!showMassImport && (
              <div>
                <div className="">
                  <h3 className="mb-3 font-pprightgothic text-2xl lg:text-4xl font-medium uppercase tracking-widestXl">
                    kundkort
                  </h3>
                </div>
                <form name="userInfo" className="mt-12">
                  <div className="">
                    <div className="flex flex-col lg:flex-row lg:gap-4">
                      <div className="mat-form-field mb-5 flex flex-1 flex-col pb-2">
                        <label
                          className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                          htmlFor="advisor_first_name"
                        >
                          Förnamn
                        </label>
                        <input
                          className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-base placeholder:text-sm placeholder:text-[#CFCAB8]"
                          name="advisor_first_name"
                          id="advisor-first-name"
                          type="text"
                          placeholder="Förnamn"
                          onChange={e => setName(e.target.value)}
                          value={name}
                        />
                        {nameError && nameDirty && (
                          <label className="error mt-1 text-[#EA3324]">
                            {nameError}
                          </label>
                        )}
                      </div>
                      <div className="mat-form-field mb-5 flex flex-1 flex-col pb-2">
                        <label
                          className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                          htmlFor="advisor_last_name"
                        >
                          Efternamn
                        </label>
                        <input
                          className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                          name="advisor_last_name"
                          id="advisor-last-name"
                          type="text"
                          placeholder="Efternamn"
                          onChange={e => setSurname(e.target.value)}
                          value={surname}
                        />
                        {surnameError && surnameDirty && (
                          <label className="error mt-1 text-[#EA3324]">
                            {surnameError}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="mat-form-field mb-5 flex flex-col pb-2">
                      <label
                        className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                        htmlFor="userName"
                      >
                        Personnummer
                      </label>
                      <input
                        className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                        name="userName"
                        id="idNumber"
                        type="text"
                        placeholder="Personnummer"
                        onChange={e => setPersonnumber(e.target.value)}
                      />
                      {personnumberError && personnumberDirty && (
                        <label className="error text-error mt-1 text-[#EA3324]">
                          {personnumberError}
                        </label>
                      )}
                    </div>
                    <div>
                      <div className="mat-form-field mb-5 flex flex-col pb-2">
                        <label
                          className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                          htmlFor="email"
                        >
                          Mailadress
                        </label>
                        <input
                          className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                          name="email"
                          id="email"
                          type="text"
                          placeholder="Mailadress"
                          onChange={e => setEmail(e.target.value)}
                          defaultValue={email}
                        />
                        {emailError && emailDirty && (
                          <label className="error text-error mt-1 text-[#EA3324]">
                            {emailError}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="mat-form-field mb-5 flex flex-col pb-2">
                      <label
                        className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                        htmlFor="currentNumber"
                      >
                        Telefonnummer
                      </label>
                      <input
                        name="userPhone"
                        id="user-phone"
                        type="tel"
                        data-cy="phone-number"
                        className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                        placeholder="Telefonnummer"
                        onChange={e => setPhone(e.target.value)}
                      />
                      {phoneError && phoneDirty && (
                        <label className="error text-error mt-1 text-[#EA3324]">
                          {phoneError}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="mt-16 flex flex-col items-center lg:items-end">
                    <button
                      id="importera_kund_2"
                      className="mt-1 flex items-center rounded bg-deep-blue px-7 py-3 text-right font-inter font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80"
                      onClick={(e) => onSubmitClick(e)}
                    >
                      importera kund
                    </button>
                    <div
                      id="importera_flera"
                      className="mt-3 flex cursor-pointer justify-center lg:self-end px-8 font-inter text-sm font-medium uppercase tracking-widest underline"
                      onClick={() => handleMassImport()}
                    >
                      Importera flera
                    </div>
                  </div>
                </form>
              </div>
            )}
            {showMassImport && (
              <div>
                <div className="">
                  <h3 className="mb-3 font-pprightgothic text-2xl lg:text-4xl font-medium uppercase tracking-widestXl">
                    Importera flera kunder
                  </h3>
                  <p>
                    Klistra in dina kunders kontaktuppgifter i rutan nedan. För
                    att gå vidare till nästa steg behöver du först spara varje
                    kontaktuppgift under lämplig kategori i listan.
                  </p>
                </div>
                <form name="userInfo" className="mt-12">
                  <div className="">
                    {!showMassImportPreview && (
                      <textarea
                        name="massimport"
                        id="massimport-data"
                        className="h-30 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] p-4 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                        placeholder="Ange vilken typ av data varje kolumn innehåller för att gå vidare till nästa steg."
                        value={valueMassimport}
                        onChange={(e) => setValueMassimport(e.target.value)}
                      />
                    )}
                    {showMassImportPreview && renderDataPreview()}
                  </div>
                  <div className="mt-16 flex flex-col items-center lg:items-end">
                    {!showMassImportPreview && (
                      <div
                        id="spara_och_fortsatt"
                        className="mt-1 flex cursor-pointer items-center rounded bg-deep-blue px-7 py-3 text-center lg:text-right font-inter font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80"
                        onClick={() => handleMassImportPreview()}
                      >
                        Spara och fortsätt
                      </div>
                    )}
                    {showMassImportPreview && arrayMassimport.length > 0 && (
                      <div
                        className="mt-1 flex cursor-pointer items-center rounded bg-deep-blue px-7 py-3 text-center lg:text-right font-inter font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80"
                        onClick={(e) => onSubmitBatchClick(e)}
                      >
                        Massimportera {arrayMassimport.length} kunder
                      </div>
                    )}
                    <div
                      id="importera_en_kund"
                      className="mt-3 cursor-pointer pr-3 text-right font-inter text-sm font-medium uppercase tracking-widest underline"
                      onClick={() => handleMassImport()}
                    >
                      Importera en kund
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
