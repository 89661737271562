import React, {useEffect, useContext, useState, useRef} from "react";
import {isDesktopView} from "../services/utils";
import QRC from "./QRCode";
import {GlobalState} from "../context/GlobalState";
import {setInStorage} from "../services/storage";
import {useUserContext, useUserDispatchContext} from "../context/UserContext";
import {useNavigate} from "react-router-dom";
import {apiUrl} from "../services/ida";
import jwt_decode from "jwt-decode";
import Button from "./Button";
import LoaderIcon from "./LoaderIcon";

const bankIdLogo = new URL(
  "../assets/images/bankid-white.svg",
  import.meta.url
);

export default function LoginModule({onUpdateLoginStatus}:any) {

  const {fenixPartnerUser, setFenixPartnerUser} = useContext(GlobalState);
  const [status, setStatus] = useState("init");
  const [loading, setLoading] = useState(true);
  const [qrHash, setQrHash] = useState();
  const [orderRef, setOrderRef] = useState();
  const [autoStartToken, setAutoStartToken] = useState();
  const qrInterval = useRef<NodeJS.Timer | null>(null);
  const collectInterval = useRef<NodeJS.Timer | null>(null);
  const {user, fetchUserData} = useUserContext();
  const navigate = useNavigate();

  const userDispatch = useUserDispatchContext();

  useEffect(() => {

      onUpdateLoginStatus(status);

  }, [status]);

  useEffect(() => {
    document.body.classList.add("login");

    return () => {
      document.body.classList.remove("login");
    };
  }, []);

  useEffect(() => {
    void startLogin();

    return () => {
      clearFetchQrHash();
      setStatus("init");
    };
  }, []);

  useEffect(() => {
    if (!orderRef) {
      return;
    }

    const qInt = setInterval(() => {
      void fetchQrHash();
    }, 2000);
    qrInterval.current = qInt;

    const cInt = setInterval(() => {
      void fetchCollect();
    }, 2000);
    collectInterval.current = cInt;

    return () => {
      clearInterval(qInt);
      clearInterval(cInt);
    };
  }, [orderRef]);

  const startLogin = async () => {
    setStatus("pending");

    const res = await fetch(`${apiUrl}/public/bankid/start`, {
      method: "GET",
    }).catch((error) => {
      // Sentry.captureException(error);
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();
    setQrHash(data.qrHash);
    setOrderRef(data.orderRef);
    setAutoStartToken(data.autoStartToken);
    setLoading(false);
  };

  const fetchQrHash = async () => {
    const params = new URLSearchParams({
      orderRef: orderRef,
    } as any);
    const res = await fetch(
      `${apiUrl}/public/bankid/refreshQR?${params.toString()}`,
      {
        method: "GET",
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      }
    ).catch((error) => {
      // Sentry.captureException(error);
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();
    setQrHash(data.qrData);
  };

  const fetchCollect = async () => {
    const params = new URLSearchParams({
      orderRef: orderRef,
    } as any);
    const res = await fetch(
      `${apiUrl}/public/bankid/collect/partner?${params.toString()}`,
      {
        method: "GET",
      }
    ).catch((error) => {
      // Sentry.captureException(error);
      console.error(error);
    });
    if (!res?.ok) {
      return null;
    }

    const data = await res.json();

    const hintCode = data.hintCode;
    const collectStatus = data.status;
    const accessToken = data.accessToken;
    const refreshToken = data.refreshToken;
    const expiresIn = data.expiresIn;

    let decoded: any = data.accessToken ? jwt_decode(data.accessToken) : null;

    if (accessToken) {
      clearFetchQrHash();
      setInStorage("FenixATkn", accessToken);
      setInStorage("FenixRTkn", refreshToken);
      const payload = {
        profile: decoded,
        firstName: decoded.email,
        lastName: decoded.email,
        personNumber: decoded.personNummer,
        accessToken: accessToken,
        refreshToken: refreshToken,
        partnerRoles: JSON.parse(decoded.partnerRoles),
        expDate: Date.now() + parseInt(expiresIn) * 1000,
      };
      userDispatch({
        type: "set-data",
        payload,
      });

      navigate("/kunder");

      const datastorePromise = fetchUserData(accessToken);

      return;
    }

    switch (hintCode) {
      case "userCancel":
        setStatus("canceled");
        clearFetchQrHash();
        break;
      case "userSign":
        setStatus("signing");
        if (qrInterval.current) {
          clearInterval(qrInterval.current);
        }
        setQrHash(undefined);
        break;
      case "startFailed":
        setStatus("failure");
        clearFetchQrHash();
        break;
      case "outstandingTransaction":
      default:
        break;
    }

  };

  const clearFetchQrHash = () => {
    if (qrInterval.current) {
      clearInterval(qrInterval.current);
    }
    if (collectInterval.current) {
      clearInterval(collectInterval.current);
    }
    setQrHash(undefined);
  };

  const renderCancelButton = () => {
    return (
      <Button
        onClick={() => {
          clearFetchQrHash();
          setStatus("init");
          navigate("/");
        }}
        title="Stäng"
        size="small"
        cssClasses="mt-5 mx-auto"
        rightArrow={false}
      />
    );
  };

  const renderStartButton = () => {

    return (
      <Button
        onClick={() => {
          setLoading(true);
          startLogin();
        }}
        title="LOGGA IN MED MOBILT BANKID"
        cssClasses="mx-auto mt-5 lg:mt-10"
        icon={{url: bankIdLogo, width: 30}}
      />
    );
  };

  const renderOpenAppButton = (autoStartToken: any) => {
    if (isDesktopView()) {
      return (
        <a
          className="font-inter font-medium uppercase tracking-widestXl"
          href={`bankid:///?autostarttoken=${autoStartToken}&redirect=null`}
        >
          Använd BankID på denna enhet
        </a>
      );
    } else {
      return (
        <Button
          onClick={() => {
            window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`;
          }}
          title="Öppna BankID-appen"
          cssClasses="mx-auto mt-5"
          icon={{url: bankIdLogo, width: 30}}
        />
      );
    }
  };

  const renderText = () => {
    return (
      <div className="mx-auto mt-6 text-center font-interlight text-[11px] text-[#736F62]">
        Genom att fortsätta accepterar jag{" "}
        <a
          className="underline"
          href="https://fenixbegravning.se/pdf/Fenix-Allmanna-villkor.pdf"
        >
          villkoren
        </a>{" "}
        för Fenix Familys tjänst samt bekräftar att jag har läst Fenix Familys{" "}
        <a
          className="underline"
          href="https://fenixbegravning.se/pdf/Fenix-Integritetspolicy.pdf"
        >
          integritetspolicy
        </a>
        .
      </div>
    );
  };

  if (loading) {
    return (
      <div className="mx-auto flex max-w-xl justify-center text-center">
        <LoaderIcon theme="dark"/>
      </div>
    );
  }
  return (
    <div className="mx-auto w-full max-w-internal">
      <div
        className="relative z-10 box-border rounded border border-solid border-light-grey bg-white px-10 pb-20 pt-16 lg:pb-52 lg:pt-16">
        {status === "init" && renderStartButton()}
        {status === "pending" && (
          <>
            <div className="mb-5 text-left lg:px-5">
              <h1 className="mb-6 inter-regular font-semibold text-lg text-[18px]">
                Skapa konto genom att logga in med mobilt bankid:
              </h1>
              {isDesktopView() && (
                <ul
                  className="list-outside list-decimal border-b border-light-grey pb-7 pl-4 font-inter text-base font-semibold uppercase tracking-wide lg:pl-12">
                  <li className="pb-2">Öppna BankID-appen i mobilen.</li>
                  <li className="pb-2">
                    Tryck på QR-symbolen i BankID-appen.
                  </li>
                  <li>Rikta kameran mot QR-koden i denna ruta.</li>
                </ul>
              )}
            </div>
            <div className="mx-auto mt-16 h-auto w-full lg:mt-16 lg:max-w-[340px]">
              {isDesktopView() && <QRC value={qrHash ? qrHash : ""}/>}

              {autoStartToken && (
                <div className="mt-5 text-center">
                  {renderOpenAppButton(autoStartToken)}
                </div>
              )}
            </div>
            {isDesktopView() && renderCancelButton()}
            {renderText()}
          </>
        )}
        {status === "signing" && (
          <>
            <div className="mx-auto mb-5 max-w-[500px]">
              Något gick fel. Det kan bero på tekniska problem eller att det
              ännu inte finns en användare kopplad till ditt BankID. Kontakta
              oss på{" "}
              <a className="underline" href="mailto:support@fenixfamily.se">
                support@fenixfamily.se
              </a>{" "}
              om du fortsatt inte lyckas logga in eller behöver hjälp med att
              skapa en användare till portalen.
            </div>
            {renderCancelButton()}
          </>
        )}
        {status === "canceled" && (
          <>
            <div className="mx-auto mb-5 max-w-[400px]">
              Identifieringen avbröts. <br/> Prova igen!
            </div>
            {renderStartButton()}
          </>
        )}
        {status === "failure" && (
          <>
            <div className="mx-auto mb-5 max-w-[400px]">
              Vi kunde inte hitta något BankID eller så misslyckades
              scanningen av QR-koden. Prova igen!
            </div>
            {renderStartButton()}
          </>
        )}
      </div>
    </div>
  );
}