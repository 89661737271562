import React from "react";

interface CheckProps {
  index: number;
  id: string;
  title: string;
  isChecked?: boolean;
  disabled?: boolean;
  checkboxClass: string;
  checkHandler: any
}

export default function Checkbox({id, title, isChecked, disabled, checkboxClass, checkHandler, index}: CheckProps) {
  return (
    <input
      type="checkbox"
      id={`checkbox-${index}`}
      // key={`checkbox-${index}`}
      checked={isChecked}
      onChange={checkHandler}
      className={isChecked ? checkboxClass + " checked mat-checkbox-input border border-[#000000] bg-[#000000]" : checkboxClass + " mat-checkbox-input border border-[#000000] bg-white"}
    />
  );
};