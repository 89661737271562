import * as React from "react";
import InviteModalContent from "./InviteModalContent";

export default function InviteModalBody(props: any) {

  return (
    <>
      <div className="max-w-modal-invite mx-auto">
        <div className="invite-full-screen-dialog advisor-dialog advisors">
          <div className="lg:pt-36 lg:pb-24 py-16">
            <div>
              <div className="">
                <h3 className="font-pprightgothic text-2xl lg:text-4xl uppercase tracking-widestXl font-medium mb-3">Bjud in dina
                  kunder till Fenix Family</h3>
                <p className="text-lg font-light font-inter">Du är bara några få klick ifrån att bjuda in dina kunder
                  till Fenix Family. Välj enkelt vilka kunder du vill bjuda in och vilken pakettyp du önskar erbjuda
                  dom. Du kan se en förhandsvisning av mailet som kommer skickas till dina valda kunder innan du klickar
                  på "skicka inbjudan".</p>
              </div>
              <div className=" bg-white email-block">
                <InviteModalContent data={props.data} onSubmit={props.onSubmit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
