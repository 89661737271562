import React, { useEffect, useState, useContext } from "react";

import Layout from "../components/Layout";
import Navigation from "../components/navigation";
import ClickReveal from "../components/ClickReveal";
import Article from "../components/invite-modal-article";
import { useNavigate } from "react-router-dom";
import { getFromStorage } from "../services/storage";
import { GlobalState } from "../context/GlobalState";
import VideoItem from "../components/VideoItem";

let data = require("../assets/data/data.json");

interface Resource {
  id: string;
  title: string;
  thumb: string;
  link: string;
  body: string;
  checked: boolean;
}

export default function Support() {
  const { fenixPartnerUser, setFenixPartnerUser } = useContext(GlobalState);
  const [articles, setArticles] = useState<Array<Resource>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setArticles(data.support);
  }, [data.checklist]);

  useEffect(() => {
    if (
      fenixPartnerUser.data.firstName == "" &&
      fenixPartnerUser.data.lastName == ""
    ) {
      const storageFenixPartnerUser = getFromStorage(
        "fnxPartnerUser",
        fenixPartnerUser
      );
      setFenixPartnerUser(storageFenixPartnerUser);
    }
  }, [fenixPartnerUser]);

  useEffect(() => {
    document.body.classList.add("support");
    const fenixToken = getFromStorage("FenixATkn", null);
    if (fenixToken) {
    } else {
      navigate("/");
    }
    console.log(fenixToken)

    return () => {
      document.body.classList.remove("support");
    };
  }, []);

  return (
    <Layout mainClass="relative flex flex-grow flex-col bg-[#FFFEFB] bg-[center_top] bg-no-repeat">
      <Navigation />
      <div className="mx-auto w-full max-w-internal">
        <div className="relative z-10 box-border rounded-b border-x border-b border-solid border-light-grey bg-white px-3 lg:px-24 lg:pb-40 pb-20 pt-10 lg:pt-24">
          <div className="support-details">
            <div className="bg-white">
              <div className="my-account__title mb-7">
                <h1 className="font-pprightgothic text-2xl lg:text-4xl font-medium uppercase tracking-widestXl">
                  Support
                </h1>
              </div>
              <p className="font-inter text-lg font-light">
                Vi finns här för dig om du behöver lite stöd på vägen, ta
                kontakt med oss på det sätt du föredrar. Vi är tillgängliga via
                chatt, telefon och mail. Vill du ha en snabb och enkel genomgång
                över portalens funktionalitet? Klicka på videon längst ned på
                denna sidan för att ta del av vår introduktionsvideo.
              </p>
              <ClickReveal />
            </div>
          </div>
          <div className="mt-20 border-b border-light-grey pb-3 font-inter text-base font-medium uppercase tracking-widest">
            Dina kontaktpersoner
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="mt-5 flex flex-col pt-2 font-inter text-sm font-medium">
              Claudiu Moldovan
              <div className="table-line pt-0 text-sm leading-4">
                <span className="mt-2 flex flex-col text-xxs text-light-grey">
                  <a className="" href="tel:0735462061">073-5462061</a>
                </span>
                <span className="flex flex-col text-xxs text-light-grey underline">
                  <a className="" href="mailto:claudiu@fenixfamily.se">claudiu@fenixfamily.se</a>
                </span>
              </div>
            </div>
            <div className="mt-5 flex flex-col pt-2 font-inter text-sm font-medium">
              Johan Nordahl
              <div className="table-line pt-0 text-sm leading-4">
                <span className="mt-2 flex flex-col text-xxs text-light-grey">
                  <a className="" href="tel:0709934045">070-9934045</a>
                </span>
                <span className="flex flex-col text-xxs text-light-grey underline">
                  <a className="" href="mailto:jn@fenixfamily.se">jn@fenixfamily.se</a>
                </span>
              </div>
            </div>
            <div className="mt-5 flex flex-col pt-2 font-inter text-sm font-medium">
              Gry Johansson
              <div className="table-line pt-0 text-sm leading-4">
                <span className="mt-2 flex flex-col text-xxs text-light-grey">
                  <a className="" href="tel:0737250457">073-7250457</a>
                </span>
                <span className="flex flex-col text-xxs text-light-grey underline">
                  <a className="" href="mailto:gj@fenixfamily.se">gj@fenixfamily.se</a>
                </span>
              </div>
            </div>
          </div>
          <div className="mt-20 lg:mt-40 border-b border-light-grey pb-3 font-inter text-base font-medium uppercase tracking-widest">
            hjälpcenter
          </div>
          <div className="article-grid">
            <div className="article relative mt-8 rounded overflow-hidden mb-3 lg:w-8/12">
              <VideoItem containerClasses=" w-11/12 rounded"/>
            </div>
            {articles.map((resource, index) => (
              <Article
                key={index}
                index={index}
                id={resource.id}
                hide={true}
                title={resource.title}
                image={resource.thumb}
                link={resource.link}
                body={resource.body}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
