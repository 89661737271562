import * as Sentry from "@sentry/react";

export const apiUrl: any = process.env.REACT_APP_IDA_API_URL;
const tenantId = 1;

export const postPartnerCustomerEvent = async (
  accessToken: string,
  partnerId: number,
  customerId: number
): Promise<any> => {
  const postData = {
    status: "DocumentIsReady",
    eventDetails: "153",
  };

  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/customer/${customerId}/event`,
    {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    // const data = await response.json();
    // return data.success ?? Promise.reject(new Error(`No data`));
    return true;
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const postPartnerUserRequestDataConsent = async (
  accessToken: string,
  partnerId: number,
  customerId: number
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/customer/${customerId}/requestcustomerdataconsent`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.success ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPartnerUser = async (
  accessToken: string,
  partnerId: number
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/${partnerId}/user`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const postPartnerUser = async (
  accessToken: string,
  partnerId: number,
  data: any
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/${partnerId}/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPartnerUserCustomerSummary = async (
  accessToken: string,
  partnerId: number
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/user/customersummary`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const putPartnerUserCustomerSummaryEmail = async (
  accessToken: string,
  partnerId: number,
  enableCustomerSummary: boolean
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/user/customersummaryemail?enableCustomerSummary=${enableCustomerSummary}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPartnerPackage = async (
  accessToken: string,
  partnerId: number
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/package`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPartnerPackageBatches = async (
  accessToken: string,
  partnerId: number
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/package/batch`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPartnerPackageBatch = async (
  accessToken: string,
  partnerId: number,
  partnerPackageId: number,
  partnerPackageBatchId: number
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/package/${partnerPackageId}/batch/${partnerPackageBatchId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPartnerCustomers = async (
  accessToken: string,
  partnerId: number
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/customer`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const postPartnerCustomers = async (
  accessToken: string,
  partnerId: number,
  dataPayload: any
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/${partnerId}/customer`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(dataPayload),
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    Sentry.captureMessage("IDA-API-postPartnerCustomers", {
      extra: {
        api: "postPartnerCustomers",
        dataPPrequestToIDA: dataPayload,
        dataIDAresponse: data,
      },
    });
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const postCreateInvitation = async (
  accessToken: string,
  partnerId: number,
  partnerPackageId: number,
  data: any
): Promise<any> => {
  const response = await fetch(
    // `${apiUrl}/public/partner/${partnerId}/package/${partnerPackageId}/invite`,
    `${apiUrl}/public/partner/${partnerId}/package/invite`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const postSendEmail = async (
  accessToken: string,
  partnerId: number,
  data: any
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/${partnerId}/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.success ?? Promise.reject(new Error(`No data (email)`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data (email)`));
  }
};

export const getProfileInfo = async (accessToken: string): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/partner/profile?tenantId=${tenantId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const updateProfileInfo = async (
  accessToken: string,
  data: {}
): Promise<any> => {
  const response = await fetch(
    `${apiUrl}/public/customer/profile?tenantId=${tenantId}`,
    {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getUserData = async (accessToken: string): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/datastore`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getUserDataByKey = async (
  accessToken: string,
  key: string
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/datastore/${key}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? false;
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const setUserData = async (
  accessToken: string,
  data: { key: string; value: string }[]
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/datastore`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const deleteUserDataByKey = async (
  accessToken: string,
  key: string
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/partner/datastore/${key}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    return true ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getPackageById = async (
  accessToken: string,
  id: number
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/package/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const setUserPackage = async (
  accessToken: string,
  id: number
): Promise<any> => {
  const data = { packageId: id };
  const response = await fetch(
    `${apiUrl}/public/customer/package?tenantId=${tenantId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const getUserPackage = async (accessToken: string): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/customer/package`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};

export const refreshAccessToken = async (
  accessToken: string,
  refreshToken: string
): Promise<any> => {
  const response = await fetch(`${apiUrl}/public/bankid/refreshtoken`, {
    method: "POST",
    body: JSON.stringify({
      AccessToken: accessToken,
      RefreshToken: refreshToken,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error: string) => {
    Sentry.captureMessage(error);
    throw Error(error);
  });

  if (response.ok) {
    const data = await response.json();
    return data.object ?? Promise.reject(new Error(`No data`));
  } else {
    return Promise.reject(new Error(`Unable to fetch data`));
  }
};
