import * as store from "store";
import cookieStorage from "store/storages/cookieStorage";

export const tenantKey = "fnxTnt";
export const userKey = "fnxUser";
export const conversationKey = "fnxConv";
export const partnerKey = "fnxPrt";

export function getFromStorage(key: any, optionalDefaultValue: any) {
  return store.get(key, optionalDefaultValue);
}

export function setInStorage(key: any, data: any) {
  return store.set(key, data);
}

export function setCookie(key: any, data: any) {
  return cookieStorage.write(key, data);
}

export function getCookie(key: any) {
  return cookieStorage.read(key);
}

export function removeFromStorage(key: any) {
  return store.remove(key);
}

export function setTenant(value: any) {
  return setInStorage(tenantKey, value);
}

export function getTenant() {
  return getFromStorage(tenantKey, '');
}
