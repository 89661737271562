import React from "react";
import {Link} from "react-router-dom";
import {getBankIdIcon} from "../services/icons";

export default function HomeModule({showLogin, token}: any) {

  return (
    <div className="relative flex flex-col px-5 pb-20 pt-16">
      <div
        className="max-w-[1070px] w-full relative mx-auto flex flex-grow flex-col rounded-2xl px-3">
        <div className="text-white">
          <div className="container mx-autopy-10">
            <div className="font-inter font-semibold leading-tight text-base uppercase">Välkommen till</div>
            <div className="inter-black italic text-[34px] lg:text-[64px] leading-tight">Partnerportalen</div>
            {!token ?
              <button
                onClick={() => showLogin(true)}
                className="bg-[#F7D152] text-deepblue px-11 leading-9 py-3.5 mt-12 inline-flex gap-3 hover:bg-[#f7d152ba] rounded uppercase items-center inter-regular text-sm font-semibold tracking-[.12em]">{getBankIdIcon("#0C2632")}logga
                in
              </button> : <Link
                to={"/kunder"}
                className="bg-[#F7D152] text-deepblue px-11 leading-9 py-3.5 mt-12 inline-flex gap-3 hover:bg-[#f7d152ba] rounded uppercase items-center inter-regular text-sm font-semibold tracking-[.12em]">{getBankIdIcon("#0C2632")}logga
                in
              </Link>}
          </div>
        </div>
      </div>
    </div>
  );
}