import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {getFenixLogo, getLogInIcon, getLogOutIcon} from "../services/icons";
import {GlobalState} from "../context/GlobalState";

export default function Head(props: any) {
  const {fenixPartnerUser, setFenixPartnerUser} = useContext(GlobalState);

  let textColor = props.themeColor;
  let iconsColor = props.logoColor;

  return (
    <div className="mt-8 mb-3 w-full">
      <div className="flex flex-row items-end gap-5 justify-between">
        {props.status === "failure" ?
          <a href={fenixPartnerUser && fenixPartnerUser.data.creds.partnerId > 0 ? '/' : '/'}
             className="w-[150px] cursor-pointer lg:w-[160px]">
            {getFenixLogo(iconsColor, "100%", "100%")}
          </a>
          : <Link to={fenixPartnerUser && fenixPartnerUser.data.creds.partnerId > 0 ? '/' : '/'}
                  className="w-[150px] cursor-pointer lg:w-[160px]">
            {getFenixLogo(iconsColor, "100%", "100%")}
          </Link>}
        {props.token === null ?
          <button
            onClick={() => props.showLogin(true)}
            className="flex flex-row items-end gap-2 border-[1px] border-transparent p-0 pt-3 lg:gap-3 no-underline"
          >
            {getLogInIcon(iconsColor)}
            <span
              className={`font-inter font-semibold text-sm uppercase leading-none tracking-widestXl text-white no-underline`}
            >
          logga in
        </span>
          </button> :
          fenixPartnerUser && fenixPartnerUser.data.creds.partnerId > 0 ?
            <Link
              to="/?logout=true"
              className="flex flex-row items-end gap-2 border-[1px] border-transparent p-0 pt-3 lg:gap-3 no-underline"
            >
              {getLogOutIcon(iconsColor)}
              <span
                className={`font-inter font-semibold text-sm uppercase leading-none tracking-widestXl ${textColor} no-underline`}
              >
          logga ut
        </span>
            </Link> : <Link
              to="/"
              className="flex flex-row items-end gap-2 border-[1px] border-transparent p-0 pt-3 lg:gap-3 no-underline"
            >
              {getLogOutIcon(iconsColor)}
              <span
                className={`font-inter font-semibold text-sm uppercase leading-none tracking-widestXl text-white no-underline`}
              >
          logga in
        </span>
            </Link>}
      </div>
    </div>
  );
}