import React, { useState, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Resources from "./pages/resources";
import Support from "./pages/support";
import MyAccount from "./pages/my-account";
import { GlobalState } from "./context/GlobalState";

export default function App() {
  const [fenixPartnerUser, setFenixPartnerUser] = useState({
    data: {
      firstName: "",
      lastName: "",
      personNumber: "",
      email: "",
      phoneNumber: "",
      wantSummaryEmail: false,
      creds: {
        tokenAuth: "",
        partnerId: 0,
      },
    },
  });
  const value = useMemo(
    () => ({ fenixPartnerUser, setFenixPartnerUser }),
    [fenixPartnerUser]
  );
  return (
    <GlobalState.Provider value={value}>
      <Routes>
        <Route path="/">
          <Route path="/" element={<Login />} />
          <Route path="/kunder" element={<Dashboard />} />
          <Route path="/resurser" element={<Resources />} />
          <Route path="/support" element={<Support />} />
          <Route path="/logga" element={<MyAccount />} />
        </Route>
      </Routes>
    </GlobalState.Provider>
  );
}
