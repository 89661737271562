import React, { useState, useEffect } from "react";

import TabNavItem from "./TabNavItem";
import TabContent from "./TabContent";
import FamilyMember from "./FamilyMember";
import FamilyMemberDetail from "./FamilyMemberDetail";
import {
  getCheckIcon,
  getPersonIcon,
  getRightArrow,
  getWarningIcon,
} from "../services/icons";

type FamilyType = {
  client: {
    name: string;
    personNumber: string;
    family:
      | {
          name: string;
          surname: string;
          personNumber: string;
          role: string;
          phone?: string;
          email?: string;
          statuses?: string[];
        }[]
      | null;
  };
};

const family: FamilyType[] = [
  {
    client: {
      name: "Agda Persson",
      personNumber: "19330128-9264",
      family: [
        {
          name: "Åsa",
          surname: "Jacobson",
          personNumber: "19850325-1545",
          role: "sambo",
          phone: "0701234567",
          email: "asa.jacobson@gmail.com",
          statuses: [
            "Arvtagare",
            "Fullmaktshavare",
            "Granskare framtidsfullmakt",
            "Kontaktperson för begravning",
          ],
        },
        {
          name: "Åsa",
          surname: "Jacob",
          personNumber: "19830325-1535",
          role: "farbror",
          phone: "0701234547",
          email: "asa2.13jacobson@gmail.com",
        },
        {
          name: "Ela",
          surname: " Jacob",
          personNumber: "19630325-1235",
          role: "moster",
        },
        {
          name: "Betty",
          surname: "Sjönvall",
          personNumber: "19800325-1145",
          role: "barn",
        },
        {
          name: "Ruth",
          surname: "Sjönvall",
          personNumber: "19850325-1146",
          role: "barnbarn",
        },
        {
          name: "Henrik",
          surname: "Sjönvall",
          personNumber: "19350325-1146",
          role: "farföräldrer",
        },
        {
          name: "Linnet",
          surname: "Sjönvall",
          personNumber: "19450325-1146",
          role: "morföräldrer",
        },
        {
          name: "Percy",
          surname: "Blomqvist",
          personNumber: "19650325-1565",
          role: "föräldrar",
        },
        {
          name: "Inger",
          surname: "Sjönvall Blomqvist",
          personNumber: "19900325-1745",
          role: "föräldrar",
        },
        {
          name: "Erika",
          surname: "Rosenbaum",
          personNumber: "19700325-1845",
          role: "syskon",
        },
        {
          name: "Jonny",
          surname: "Blomqvist",
          personNumber: "19800325-1945",
          role: "syskon",
        },
      ],
    },
  },
  {
    client: {
      name: "Oleksandr Nykytyuk",
      personNumber: "19820417-7433",
      family: [
        {
          name: "Åsa",
          surname: "Jacobson",
          personNumber: "19850325-1545",
          role: "sambo",
          phone: "0701234567",
          email: "asa.jacobson@gmail.com",
          statuses: [
            "Arvtagare",
            "Fullmaktshavare",
            "Granskare framtidsfullmakt",
            "Kontaktperson för begravning",
          ],
        },
        {
          name: "Åsa",
          surname: "Jacob",
          personNumber: "19830325-1535",
          role: "farbror",
          phone: "0701234547",
          email: "asa2.13jacobson@gmail.com",
        },
        {
          name: "Ela",
          surname: " Jacob",
          personNumber: "19630325-1235",
          role: "moster",
        },
        {
          name: "Betty",
          surname: "Sjönvall",
          personNumber: "19800325-1145",
          role: "barn",
        },
        {
          name: "Ruth",
          surname: "Sjönvall",
          personNumber: "19850325-1146",
          role: "barnbarn",
        },
        {
          name: "Henrik",
          surname: "Sjönvall",
          personNumber: "19350325-1146",
          role: "farföräldrer",
        },
        {
          name: "Linnet",
          surname: "Sjönvall",
          personNumber: "19450325-1146",
          role: "morföräldrer",
        },
        {
          name: "Percy",
          surname: "Blomqvist",
          personNumber: "19650325-1565",
          role: "föräldrar",
        },
        {
          name: "Inger",
          surname: "Sjönvall Blomqvist",
          personNumber: "19900325-1745",
          role: "föräldrar",
        },
        {
          name: "Erika",
          surname: "Rosenbaum",
          personNumber: "19700325-1845",
          role: "syskon",
        },
        {
          name: "Jonny",
          surname: "Blomqvist",
          personNumber: "19800325-1945",
          role: "syskon",
        },
      ],
    },
  },
  {
    client: {
      name: "Denys Yershakov",
      personNumber: "197801067419",
      family: [
        {
          name: "Åsa",
          surname: "Jacobson",
          personNumber: "19850325-1545",
          role: "moster",
          phone: "0701234547",
          email: "asa3.13jacobson@gmail.com",
          statuses: [
            "Arvtagare",
            "Granskare framtidsfullmakt",
            "Kontaktperson för begravning",
          ],
        },
        {
          name: "Åsa",
          surname: "Jacob",
          personNumber: "19830325-1535",
          role: "make",
        },
        {
          name: "Ela",
          surname: "Jacob",
          personNumber: "19630325-1235",
          role: "förälder",
        },
        {
          name: "Betty",
          surname: "Sjönvall",
          personNumber: "19800325-1145",
          role: "barn",
        },
        {
          name: "Erika",
          surname: "Rosenbaum",
          personNumber: "19700325-1845",
          role: "morbror",
        },
        {
          name: "Jonny",
          surname: "Blomqvist",
          personNumber: "19800325-1945",
          role: "vän",
        },
      ],
    },
  },
  {
    client: {
      name: "Henrik Testkund",
      personNumber: "19940818-2385",
      family: [
        {
          name: "Åsa",
          surname: "Jacobson",
          personNumber: "19850325-1545",
          role: "farbror",
        },
        {
          name: "Åsa",
          surname: "Jacob",
          personNumber: "19830325-1535",
          role: "särbo",
        },
        {
          name: "Ela",
          surname: "Jacob",
          personNumber: "19630325-1235",
          role: "maka",
          phone: "0701234547",
          email: "asa.jacobson@gmail.com",
        },
        {
          name: "Betty",
          surname: "Sjönvall",
          personNumber: "19800325-1145",
          role: "morföräldrer",
        },
        {
          name: "Ruth",
          surname: "Sjönvall",
          personNumber: "19850325-1146",
          role: "barnbarn",
        },
        {
          name: "Henrik",
          surname: "Sjönvall",
          personNumber: "19350325-1146",
          role: "farföräldrer",
        },
        {
          name: "Linnet",
          surname: "Sjönvall",
          personNumber: "19450325-1146",
          role: "morföräldrer",
        },
        {
          name: "Percy",
          surname: "Blomqvist",
          personNumber: "19650325-1565",
          role: "föräldrar",
        },
        {
          name: "Inger",
          surname: "Sjönvall Blomqvist",
          personNumber: "19900325-1745",
          role: "föräldrar",
        },
        {
          name: "Erika",
          surname: "Rosenbaum",
          personNumber: "19700325-1845",
          role: "morbror",
        },
        {
          name: "Jonny",
          surname: "Blomqvist",
          personNumber: "19800325-1945",
          role: "syskon",
        },
      ],
    },
  },
];

export default function Tabs({ client, onSubmit }: any) {
  const [childData, setChildData] = useState();
  const [activePerson, setActivePerson] = useState("");
  const [activeTab, setActiveTab] = useState("tab1");
  const [phone, setPhone] = useState(client.customer?.phoneNumber || "");
  const [email, setEmail] = useState(client.customer?.email || "");
  const [name, setName] = useState(client.customer?.firstName || "");
  const [surname, setSurname] = useState(client.customer?.lastName || "");
  const [personnumber, setPersonnumber] = useState(
    client.customer?.personNumber || ""
  );

  useEffect(() => {
    const storedActivePerson = localStorage.getItem(
      "activePerson-" + client.customer?.personNumber
    );
    if (storedActivePerson && client.customer.family) {
      setActivePerson(storedActivePerson);
      const filteredFamily = client.customer.family.filter(
        (person: any) => person.personNumber === storedActivePerson
      );
      setChildData(filteredFamily[0]);
    }
  }, []);

  useEffect(() => {
    // Write activePerson to localStorage whenever it changes
    localStorage.setItem(
      "activePerson-" + client.customer?.personNumber,
      activePerson
    );
  }, [activePerson]);

  let foundMatch = false;
  for (const familyMember of family) {
    if (familyMember.client.personNumber === client.customer.personNumber) {
      client.customer.family = familyMember.client.family;
      foundMatch = true;
      break;
    }
  }

  if (!foundMatch) {
    client.customer.family = null;
  }

  function onShareClick(e: any) {
    e.preventDefault();
    let dataSubmit = {
      id: null,
      customerIdaAccountId: null,
      firstName: "",
      lastName: "",
      personNumber: "",
      phoneNumber: "",
      email: "",
      share: true,
    };

    let canSubmit = false;

    canSubmit = true;

    if (canSubmit) {
      dataSubmit.id = client.customer.id;
      dataSubmit.customerIdaAccountId = client.customer.customerIdaAccountId;
      dataSubmit.email = email;
      dataSubmit.personNumber = personnumber;
      dataSubmit.lastName = surname;
      dataSubmit.firstName = name;
      dataSubmit.phoneNumber = phone;
      dataSubmit.share = true;
      onSubmit([dataSubmit]);
    }
    return false;
  }

  function onRestrictClick(e: any) {
    e.preventDefault();
    let dataSubmit = {
      id: null,
      customerIdaAccountId: null,
      firstName: "",
      lastName: "",
      personNumber: "",
      phoneNumber: "",
      email: "",
      share: false,
      dataSharingConsent: null,
      consentRequestedByPartner: null,
    };

    let canSubmit = false;

    canSubmit = true;

    if (canSubmit) {
      dataSubmit.id = client.customer.id;
      dataSubmit.customerIdaAccountId = client.customer.customerIdaAccountId;
      dataSubmit.email = email;
      dataSubmit.personNumber = personnumber;
      dataSubmit.lastName = surname;
      dataSubmit.firstName = name;
      dataSubmit.phoneNumber = phone;
      dataSubmit.share = false;
      dataSubmit.dataSharingConsent = client.customer.dataSharingConsent;
      dataSubmit.consentRequestedByPartner =
        client.customer.consentRequestedByPartner;
      onSubmit([dataSubmit]);
    }
    return false;
  }

  let dataSharingBlock;
  const textYouHaveAlreadyRequested = `Du har redan skickat dataförfrågan till kunden.`;

  // client imported, not logged in, cannot be asked to share data
  if (client.customer?.status <= 1) {
    dataSharingBlock = (
      <div className="text-[13px] leading-4 text-[#CECABA]">
        Kunden är importerad men inte inloggad. Du kan inte begära åtkomst till
        kundens data.
      </div>
    );
  }
  // client invited, not logged in, cannot be asked to share data
  else if (client.customer?.status == 2) {
    dataSharingBlock = (
      <div className="text-[13px] leading-4 text-[#CECABA]">
        Kunden är inbjuden men inte inloggad. Du kan inte begära åtkomst till
        kundens data.
      </div>
    );
  }
  // client logged in but not answered to data consent request, request can be sent
  else if (
    client.customer?.status > 2 &&
    client.customer?.dataSharingConsent == null
  ) {
    dataSharingBlock = (
      <div className="mb-2 flex flex-col justify-between gap-5 lg:flex-row">
        <div className="flex items-center gap-4">
          <p className="text-[13px] leading-4 text-[#CECABA]">
            Kunden har loggat in men har inte svarat på dataförfrågan.
            <br />
            {email !== "" && `Du kan begära tillgång till kundens data.`}
            {email == "" &&
              `Lägg till kundens mailadress för att begära tillgång till kundens data.`}
            {client.customer?.consentRequestedByPartner == true && (
              <>
                <br />
                {/* {textYouHaveAlreadyRequested} */}
              </>
            )}
          </p>
        </div>
        <button
          id="restrictData"
          className="mt-1 flex items-center rounded bg-deep-blue px-7 py-1.5 text-right font-inter text-sm font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80 lg:mt-0"
          value="restrict"
          onClick={(e) => onRestrictClick(e)}
          disabled={email == ""}
          style={email == "" ? { backgroundColor: "#cecece" } : {}}
        >
          begär tillgång till data
        </button>
      </div>
    );
  }

  // client logged in, answered to data consent POSITIVE, partner has not sent request before, request can be sent
  // KUNDDATA PDF
  else if (
    client.customer?.status > 2 &&
    client.customer?.dataSharingConsent == true &&
    client.customer?.consentRequestedByPartner == false
  ) {
    dataSharingBlock = (
      <div className="mb-2 flex flex-col justify-between gap-5 lg:flex-row">
        <div className="flex items-center gap-4">
          {getCheckIcon("", "25", "25")}{" "}
          <p className="flex-1 text-[13px] leading-4 text-[#CECABA]">
            Du har kundens medgivande angående tillgångar och skulder.
            <br />
            {/*{textYouHaveAlreadyRequested}*/}
            {/* <span className="font-medium italic">Tillgångar och skulder</span> */}
          </p>
        </div>
        <button
          id="shareData"
          className="mt-1 flex items-center justify-center rounded bg-deep-blue px-7 py-1.5 text-center font-inter text-sm font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80 lg:mt-0 lg:min-w-[280px]"
          value="share"
          onClick={(e) => onShareClick(e)}
        >
          ta del av kundens data
        </button>
      </div>
    );
  }

  // client logged in, answered to data consent POSITIVE, partner sent request before, request can be sent
  // KUNDDATA PDF
  else if (
    client.customer?.status > 2 &&
    client.customer?.dataSharingConsent == true &&
    client.customer?.consentRequestedByPartner == true
  ) {
    dataSharingBlock = (
      <div className="mb-2 flex flex-col justify-between gap-5 lg:flex-row">
        <div className="flex items-center gap-4">
          {getCheckIcon("", "25", "25")}{" "}
          <p className="flex-1 text-[13px] leading-4 text-[#CECABA]">
            Du har kundens medgivande angående tillgångar och skulder.
            <br />
            {/*{textYouHaveAlreadyRequested}*/}
            {/* <span className="font-medium italic">Tillgångar och skulder</span> */}
          </p>
        </div>
        <button
          id="shareData"
          className="mt-1 flex items-center justify-center rounded bg-deep-blue px-7 py-1.5 text-center font-inter text-sm font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80 lg:mt-0 lg:min-w-[280px]"
          value="share"
          onClick={(e) => onShareClick(e)}
        >
          ta del av kundens data
        </button>
      </div>
    );
  }
  // client logged in, answered to data consent NEGATIVE, partner has not sent request before, request can be sent
  else if (
    client.customer?.status > 1 &&
    client.customer?.dataSharingConsent == false &&
    client.customer?.consentRequestedByPartner == false
  ) {
    dataSharingBlock = (
      <div className="flex flex-col justify-between gap-5 lg:flex-row">
        <div className="flex items-center gap-4">
          {getWarningIcon()}{" "}
          <p className="flex-1 text-[13px] leading-4 text-[#CECABA]">
            Du har <span className="underline">inte</span> kundens medgivande{" "}
            angående tillgångar och skulder.
            {email == "" &&
              ` Lägg till kundens mailadress för att begära tillgång till kundens data.`}
            {/* <span className="font-medium italic">Tillgångar och skulder</span> */}
          </p>
        </div>
        <button
          id="restrictData"
          className="mt-1 flex items-center justify-center rounded bg-deep-blue px-7 py-1.5 text-center font-inter text-sm font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80 lg:mt-0 lg:min-w-[280px]"
          value="restrict"
          onClick={(e) => onRestrictClick(e)}
          disabled={email == ""}
          style={email == "" ? { backgroundColor: "#cecece" } : {}}
        >
          begär tillgång till data
        </button>
      </div>
    );
  }

  // client logged in, answered to data consent NEGATIVE, partner sent request before, request can be sent
  else if (
    client.customer?.status > 1 &&
    client.customer?.dataSharingConsent == false &&
    client.customer?.consentRequestedByPartner == true
  ) {
    dataSharingBlock = (
      <div className="flex flex-col justify-between gap-5 lg:flex-row">
        <div className="flex items-center gap-4">
          {getWarningIcon()}{" "}
          <p className="flex-1 text-[13px] leading-4 text-[#CECABA]">
            Du har <span className="underline">inte</span> kundens medgivande
            angående tillgångar och skulder.
            {email == "" &&
              ` Lägg till kundens mailadress för att begära tillgång till kundens data.`}
            {/*{textYouHaveAlreadyRequested}*/}
            {/* <span className="font-medium italic">Tillgångar och skulder</span> */}
          </p>
        </div>
        <button
          id="restrictData"
          className="mt-1 flex items-center justify-center rounded bg-deep-blue px-5 py-1.5 text-center font-inter text-sm font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80 lg:mt-0 lg:min-w-[280px]"
          value="restrict"
          onClick={(e) => onRestrictClick(e)}
          disabled={email == ""}
          style={email == "" ? { backgroundColor: "#cecece" } : {}}
        >
          begär tillgång till data
        </button>
      </div>
    );
  }

  let block1 = (
    <div className="block-1 rounded border border-[#CECABA] bg-[#FFFEFB] px-4 pb-9">
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          make / maka / sambo
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          barn
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
    </div>
  );

  let block2 = (
    <div className="block-2 rounded border border-[#CECABA] bg-[#FFFEFB] px-4 pb-9">
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          föräldrar
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          syskon
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
    </div>
  );

  let block3 = (
    <div className="block-3 rounded border border-[#CECABA] bg-[#FFFEFB] px-4 pb-9">
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          Mor-/ farföräldrer
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
    </div>
  );

  let block4 = (
    <div className="block-3 rounded border border-[#CECABA] bg-[#FFFEFB] px-4 pb-9">
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          Moster/Faster
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          Morbror/Farbror
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
    </div>
  );

  let block5 = (
    <div className="block-3 rounded border border-[#CECABA] bg-[#FFFEFB] px-4 pb-9">
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          Särbo
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          Vän
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
      <div className="person pt-7">
        <div className="person-title inter-medium border-b border-[#CECABA] pb-2 text-[12px] uppercase tracking-[.2em] text-[#736F62]">
          Kollega
        </div>
        <div className="person-detail flex items-center gap-4 pt-7 uppercase">
          <div className="person-img flex h-[48px] w-[48px] items-center justify-center rounded-full bg-[#CECABA]">
            {getPersonIcon()}
          </div>
          -
        </div>
      </div>
    </div>
  );

  const handleChildData = (dataFromChild: any) => {
    setChildData(dataFromChild);
  };

  if (client.customer.family) {
    const targetRolesBlock1: string[] = [
      "sambo",
      "make",
      "maka",
      "barn",
      "barnbarn",
    ];
    const targetRolesBlock2: string[] = ["förälder", "syskon", "syskonbarn"];
    const targetRolesBlock3: string[] = ["morföräldrer", "farföräldrer"];
    const targetRolesBlock4: string[] = [
      "moster",
      "faster",
      "morbror",
      "farbror",
    ];
    const targetRolesBlock5: string[] = ["särbo", "vän", "kollega"];

    const familyBlock1 = client.customer.family.filter(function (o: any) {
      return targetRolesBlock1.indexOf(o.role) > -1;
    });

    const familyBlock2 = client.customer.family.filter(function (o: any) {
      return targetRolesBlock2.indexOf(o.role) > -1;
    });

    const familyBlock3 = client.customer.family.filter(function (o: any) {
      return targetRolesBlock3.indexOf(o.role) > -1;
    });

    const familyBlock4 = client.customer.family.filter(function (o: any) {
      return targetRolesBlock4.indexOf(o.role) > -1;
    });

    const familyBlock5 = client.customer.family.filter(function (o: any) {
      return targetRolesBlock5.indexOf(o.role) > -1;
    });

    block1 =
      familyBlock1.length > 0
        ? familyBlock1 && (
            <FamilyMember
              roleArray={targetRolesBlock1}
              familyArray={familyBlock1}
              onFamilyMemberUpdate={handleChildData}
              activePerson={activePerson}
              setActivePerson={setActivePerson}
            />
          )
        : "";

    block2 =
      familyBlock2.length > 0
        ? familyBlock2 && (
            <FamilyMember
              roleArray={targetRolesBlock2}
              familyArray={familyBlock2}
              onFamilyMemberUpdate={handleChildData}
              activePerson={activePerson}
              setActivePerson={setActivePerson}
            />
          )
        : "";

    block3 =
      familyBlock3.length > 0
        ? familyBlock3 && (
            <FamilyMember
              roleArray={targetRolesBlock3}
              familyArray={familyBlock3}
              onFamilyMemberUpdate={handleChildData}
              activePerson={activePerson}
              setActivePerson={setActivePerson}
            />
          )
        : "";

    block4 =
      familyBlock4.length > 0
        ? familyBlock4 && (
            <FamilyMember
              roleArray={targetRolesBlock4}
              familyArray={familyBlock4}
              onFamilyMemberUpdate={handleChildData}
              activePerson={activePerson}
              setActivePerson={setActivePerson}
            />
          )
        : "";

    block5 =
      familyBlock5.length > 0
        ? familyBlock5 && (
            <FamilyMember
              roleArray={targetRolesBlock5}
              familyArray={familyBlock5}
              onFamilyMemberUpdate={handleChildData}
              activePerson={activePerson}
              setActivePerson={setActivePerson}
            />
          )
        : "";
  }

  return (
    <div className="tabs mx-auto w-full">
      <ul className="tabs-nav relative z-20 mb-0 flex overflow-hidden border-b border-[#CECABA] px-0 pb-6 text-sm font-semibold uppercase tracking-widest">
        <TabNavItem
          title="profil"
          born=""
          id="tab1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          classItem={
            "relative mr-16 pb-3 flex items-center box-border cursor-pointer text-[#736F62] after:absolute after:content-[''] after:left-0 after:bottom-0.5 after:w-full after:h-1 after:bg-transparent after:rounded"
          }
          iconItem={""}
        />
        {/* <TabNavItem
          title="familjträd" born="" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}
          classItem={"relative mr-16 pb-1.5 flex items-center box-border cursor-pointer text-[#736F62] after:absolute after:content-[''] after:left-0 after:bottom-0.5 after:w-full after:h-1 after:bg-transparent after:rounded "}
          iconItem={""}/> */}
      </ul>
      <div className="tab-content relative z-10 bg-white pb-0">
        <TabContent id="tab1" activeTab={activeTab}>
          <div className="mt-6 flex flex-col">
            <form name="userInfo" className="mt-8">
              <div className="">
                <div className="flex flex-col lg:flex-row lg:gap-4">
                  <div className="mat-form-field mb-5 flex flex-1 flex-col pb-2">
                    <label
                      className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                      htmlFor="advisor_first_name"
                    >
                      Förnamn
                    </label>
                    <input
                      className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#CFCAB8]/30 indent-5 text-base placeholder:text-sm placeholder:text-[#CFCAB8]"
                      name="advisor_first_name"
                      id="advisor-first-name"
                      type="text"
                      disabled={true}
                      placeholder="Förnamn"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="mat-form-field mb-5 flex flex-1 flex-col pb-2">
                    <label
                      className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                      htmlFor="advisor_last_name"
                    >
                      Efternamn
                    </label>
                    <input
                      className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#CFCAB8]/30 indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                      name="advisor_last_name"
                      id="advisor-last-name"
                      type="text"
                      disabled={true}
                      placeholder="Efternamn"
                      onChange={(e) => setSurname(e.target.value)}
                      value={surname}
                    />
                  </div>
                </div>
                <div className="mat-form-field mb-5 flex flex-col pb-2">
                  <label
                    className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                    htmlFor="userName"
                  >
                    Personnummer
                  </label>
                  <input
                    className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#CFCAB8]/30 indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                    name="userName"
                    id="idNumber"
                    type="text"
                    disabled={true}
                    placeholder="Personnummer"
                    value={personnumber}
                    onChange={(e) => setPersonnumber(e.target.value)}
                  />
                </div>
                <div>
                  <div className="mat-form-field mb-5 flex flex-col pb-2">
                    <label
                      className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                      htmlFor="email"
                    >
                      Mailadress
                    </label>
                    <input
                      className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#CFCAB8]/30 indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                      name="email"
                      id="email"
                      type="text"
                      disabled={true}
                      placeholder="Mailadress"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                </div>
                <div className="mat-form-field mb-5 flex flex-col pb-2">
                  <label
                    className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                    htmlFor="currentNumber"
                  >
                    Telefonnummer
                  </label>
                  <input
                    name="userPhone"
                    id="user-phone"
                    type="tel"
                    disabled={true}
                    data-cy="phone-number"
                    className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#CFCAB8]/30 indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                    placeholder="Telefonnummer"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="mat-form-field mb-5 flex flex-col pb-0">
                  <label
                    className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                    htmlFor="shareData"
                  >
                    tillgänglig data
                  </label>
                  {dataSharingBlock}
                </div>
              </div>
            </form>
          </div>
        </TabContent>
        {/* <TabContent id="tab2" activeTab={activeTab}>
          <div className="mt-16 flex flex-col justify-between overflow-hidden lg:max-h-[840px] lg:flex-row">
            <div className="family-list mb-6 w-full overflow-y-auto lg:mb-0 lg:max-w-[375px]">
              <div className="flex flex-col gap-10 lg:pl-2">
                {block1}
                {block2}
                {block3}
                {block4}
                {block5}
              </div>
            </div>
            <div className="w-full rounded-sm border border-[#CECABA] bg-[#FFFEFB] px-6 py-8 lg:max-w-[680px] lg:px-12 lg:py-16">
              {childData !== undefined ? (
                <FamilyMemberDetail familyProfile={childData} />
              ) : (
                <div>
                  <h2 className="inter-black mb-5 text-3xl italic lg:text-5xl">
                    Välkommen till{" "}
                    {name.charAt(name.length - 1) !== "s" ? name + "s" : name}{" "}
                    digitala familjeträd.{" "}
                  </h2>
                  <p className="inter-light">
                    Du har angivit en helvets massa giriga familjemedlemmar som
                    arvtagare och definierat tjänster som de behöver göra om det
                    nu ska bli några cash. Så som äldrevårdsblöjbyte och dylikt.
                  </p>
                </div>
              )}
            </div>
          </div>
        </TabContent> */}
      </div>
    </div>
  );
}
