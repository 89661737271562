import { createContext, useState, useContext, useMemo } from "react";

export const GlobalState = createContext({
  fenixPartnerUser: {
    data: {
      firstName: "",
      lastName: "",
      personNumber: "",
      email: "",
      phoneNumber: "",
      wantSummaryEmail: false,
      creds: {
        tokenAuth: "",
        partnerId: 0,
      },
    },
  },
  setFenixPartnerUser: (u: any) => {
    return u;
  },
});

// {
//   "isActive": true,
//   "id": 0,
//   "firstName": "string",
//   "lastName": "string",
//   "personNumber": "string",
//   "email": "string",
//   "phoneNumber": "string",
//   "role": 1
// }
