import React, { PropsWithChildren, useEffect, useState } from "react";
import ImportModalBody from "./import-modal-body";

type Props = PropsWithChildren<{
  onClose: () => void;
  onSubmit: (data: any) => void;
  data?: any;
}>;

const initialFormData = Object.freeze({
  firstName: "",
  lastName: "",
  personNumber: "",
  companyName: "",
  paketType: "",
  upgradeType: "",
  paketQuantity: "",
});

const ModalPackage = ({ onClose, onSubmit, data }: Props) => {
  const [quantity, setQuantity] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [upgradeType, setUpgradeType] = useState("");
  const [upgrade, setUpgrade] = useState(false);
  const [formData, updateFormData] = useState(initialFormData);

  useEffect(() => {
    const closeOnEscapeKey = (e: any) =>
      e.key === "Escape" ? onClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  const handleChange = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selectedCategory = e.target.value;
    setCurrentValue(e.target.value);
    selectedCategory == "pakettyp_3" ? setQuantity(false) : setQuantity(true);
    selectedCategory == "pakettyp_3" ? setUpgrade(true) : setUpgrade(false);
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleChangeInput = (e: any) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleChangeUpgrade = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
    const selectedUpgrade = e.target.value;
    setUpgradeType(selectedUpgrade);
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    let formDataSubmit: any = formData;
    if (formDataSubmit.firstName == "") {
      formDataSubmit.firstName = data.firstName;
    }
    if (formDataSubmit.lastName == "") {
      formDataSubmit.lastName = data.lastName;
    }
    if (formDataSubmit.personNumber == "") {
      formDataSubmit.personNumber = data.personNumber;
    }

    event.data = {
      formData: formDataSubmit,
    };
    onSubmit(event.data);
    onClose();
    return false;
  };

  return (
    <div
      className="fixed top-0 z-50 flex h-full w-full flex-col"
      style={{ backgroundColor: "rgba(0, 0, 0, .75)" }}
      onClick={() => {
        // close modal when outside of modal is clicked
        onClose();
      }}
    >
      <div
        className="mx-auto my-auto flex max-h-[90%] w-[90%] lg:w-full max-w-modal-import justify-center"
        onClick={e => {
           e.stopPropagation();}}
      >
        <div className="relative flex w-full flex-col rounded bg-[#FFFEFB] drop-shadow-xl px-4">
          <div className="absolute right-2 top-2">
            <img
              src="/images/icon-close-dark.svg"
              alt="close"
              width="18"
              height="18"
              className="box-content h-fit max-w-none cursor-pointer p-4"
              onClick={onClose}
            />
          </div>
          <div className="overflow-auto no-scrollbar">
            <div className="mx-auto max-w-import-info py-16 lg:py-24">
              <div className="invite-full-screen-dialog advisor-dialog advisors">
                <div className="">
                  <div>
                    <div className="">
                      <h3 className="mb-3 font-pprightgothic text-2xl lg:text-4xl font-medium uppercase tracking-widestXl">
                        Lägg beställning
                      </h3>
                    </div>
                    <form name="userInfo" className="mt-6 lg:mt-12">
                      <div className="">
                        <div className="flex flex-col lg:flex-row lg:gap-4">
                          <div className="mat-form-field mb-5 flex flex-1 flex-col pb-2">
                            <label
                              className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                              htmlFor="firstName"
                            >
                              Förnamn
                            </label>
                            <input
                              className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-base placeholder:text-sm placeholder:text-[#CFCAB8]"
                              name="firstName"
                              id="firstName"
                              type="text"
                              onChange={handleChangeInput}
                              placeholder=""
                              defaultValue={data.firstName}
                            />
                          </div>
                          <div className="mat-form-field mb-5 flex flex-1 flex-col pb-2">
                            <label
                              className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                              htmlFor="lastName"
                            >
                              Efternamn
                            </label>
                            <input
                              className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                              name="lastName"
                              id="lastName"
                              type="text"
                              onChange={handleChangeInput}
                              placeholder=""
                              defaultValue={data.lastName}
                            />
                          </div>
                        </div>
                        <div className="mat-form-field mb-5 flex flex-col pb-2">
                          <label
                            className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                            htmlFor="personNumber"
                          >
                            Personnummer
                          </label>
                          <input
                            className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                            name="personNumber"
                            id="personNumber"
                            type="text"
                            onChange={handleChangeInput}
                            placeholder=""
                            defaultValue={data.personNumber}
                          />
                        </div>
                        <div className="mat-form-field mb-5 flex flex-col pb-2">
                          <label
                            className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                            htmlFor="companyName"
                          >
                            Företagsnamn
                          </label>
                          <input
                            className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                            name="companyName"
                            id="companyName"
                            type="text"
                            onChange={handleChangeInput}
                            placeholder="Ange företagsnamn"
                          />
                        </div>
                        <div className="flex gap-4">
                          <div className="mat-form-field mb-5  flex flex-1 shrink flex-col pb-2">
                            <label
                              className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                              htmlFor="paketType"
                            >
                              Vad vill du beställa?
                            </label>
                            <select
                              name="paketType"
                              onChange={handleChange}
                              value={currentValue}
                              className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                            >
                              <option value="" disabled>
                                Välj pakettyp
                              </option>
                              <option value="pakettyp_1">
                                Bjud in fler kunder till Fenix Livsplanering
                              </option>
                              <option value="pakettyp_2">
                                Bjud in fler kollegor till partnerportalen
                              </option>
                              <option value="pakettyp_3">
                                Uppgradera ditt paket/din prenumeration
                              </option>
                            </select>
                          </div>
                          {quantity ? (
                            <div className="mat-form-field mb-5 flex flex-col pb-2">
                              <label
                                className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                                htmlFor="paketQuantity"
                              >
                                Antal
                              </label>
                              <input
                                className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                                name="paketQuantity"
                                id="paketQuantity"
                                onChange={handleChangeInput}
                                type="number"
                                placeholder="0"
                                min={1}
                                max={50}
                              />
                            </div>
                          ) : null}
                          {upgrade ? (
                            <div className="mat-form-field mb-5 flex  flex-1 shrink flex-col pb-2">
                              <label
                                className="mb-2 min-h-[24px] w-full font-inter text-base font-medium uppercase tracking-widest"
                                htmlFor="upgradeType"
                              >
                                {" "}
                              </label>
                              <select
                                name="upgradeType"
                                onChange={handleChangeUpgrade}
                                value={upgradeType}
                                className="h-13 w-full rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                              >
                                <option value="" disabled>
                                  Välj paket
                                </option>
                                <option value="starter">Starter</option>
                                <option value="growth">Growth</option>
                                <option value="professional">
                                  Professional
                                </option>
                                <option value="enterprise">Enterprise</option>
                              </select>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="mt-6 lg:mt-16 flex flex-col items-center lg:items-end">
                        <button
                          onClick={handleSubmit}
                          className="mt-1 flex items-center rounded bg-deep-blue px-7 py-3 text-right font-inter font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80"
                          // disabled
                        >
                          Lägg beställning
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPackage;
