import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout";
import Navigation from "../components/navigation";
import Modal from "../components/Modal";
import toast, { Toaster } from "react-hot-toast";
import Switch from "../components/SwitchCheckbox";
import jwt_decode from "jwt-decode";
import {
  apiUrl,
  getPartnerUser,
  postPartnerUser,
  getPartnerUserCustomerSummary,
  putPartnerUserCustomerSummaryEmail,
} from "../services/ida";
import { getFromStorage, removeFromStorage } from "../services/storage";
import { GlobalState } from "../context/GlobalState";

export default function MyAccount() {
  const { fenixPartnerUser, setFenixPartnerUser } = useContext(GlobalState);
  const [fenixLocalPartnerUser, setFenixLocalPartnerUser] = useState<any>();
  const [fenixLocalSwitch, setFenixLocalSwitch] = useState(false);

  const [value, setValue] = useState(false);
  const [bookModal, setBookModal] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [submitDone, setSubmitDone] = useState(false);
  const [personNumber, setPersonNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneError, setPhoneError] = useState(
    "Fel format på telefonnummer (prova 0XXXXXXXXX eller +4XXXXXXXXXX)."
  );
  const [emailError, setEmailError] = useState("Ogiltiga tecken i adressen");
  const [nameError, setNameError] = useState("Fyll i kundens förnamn");
  const [surnameError, setSurnameError] = useState(
    "Fyll i kundens efternamn"
  );
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [nameDirty, setNameDirty] = useState(false);
  const [surnameDirty, setSurnameDirty] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [decodedFenixToken, setDecodedFenixToken] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("profile");
    const fenixToken = getFromStorage("FenixATkn", null);
    if (fenixToken) {
      // const decodedFenixToken = jwt_decode(fenixToken);
      // setDecodedFenixToken(decodedFenixToken);
    } else {
      navigate("/");
    }

    return () => {
      document.body.classList.remove("profile");
    };
  }, []);

  useEffect(() => {
    if (
      fenixPartnerUser.data.firstName == "" &&
      fenixPartnerUser.data.lastName == ""
    ) {
      const storageFenixPartnerUser = getFromStorage(
        "fnxPartnerUser",
        fenixPartnerUser
      );
      setFenixPartnerUser(storageFenixPartnerUser);
      // setFenixLocalPartnerUser(storageFenixPartnerUser);
    } else {
      setFenixLocalSwitch(fenixPartnerUser.data.wantSummaryEmail);
      setFenixLocalPartnerUser(fenixPartnerUser);
      setPersonNumber(
        fenixPartnerUser && fenixPartnerUser.data.personNumber
          ? fenixPartnerUser.data.personNumber
          : ""
      );
      setName(
        fenixPartnerUser && fenixPartnerUser.data.firstName
          ? fenixPartnerUser.data.firstName
          : ""
      );
      setName(
        fenixPartnerUser && fenixPartnerUser.data.firstName
          ? fenixPartnerUser.data.firstName
          : ""
      );
      setSurname(
        fenixPartnerUser && fenixPartnerUser.data.lastName
          ? fenixPartnerUser.data.lastName
          : ""
      );
      setEmail(
        fenixPartnerUser && fenixPartnerUser.data.email
          ? fenixPartnerUser.data.email
          : ""
      );
      setPhone(
        fenixPartnerUser && fenixPartnerUser.data.phoneNumber
          ? fenixPartnerUser.data.phoneNumber
          : ""
      );
    }
  }, [fenixPartnerUser]);

  const onModalClose = () => {
    setBookModal(false);
  };

  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const re = /^((([+]46)\s*((1|7)[0236]))|(0(1|7)[0236]))\s*(([-]|())\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*|([0-9]\s*([-]|()))\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*[0-9]\s*)$/;
  const letters = /^[a-zA-ZÄÖÅ åáãâäàéêëèíîïìóõôöòøúûüùçñ\-'"`’]+$/;

  const emailHandler = (e: any) => {
    setEmail(e.target.value);
    if (!isValidEmail(e.target.value)) {
      setEmailError("Ogiltiga tecken i adressen");
      setEmailDirty(true);
    } else {
      setEmailError("");
      setEmailDirty(false);
    }
  };

  const phoneHandler = (e: any) => {
    setPhone(e.target.value);
    if (!re.test(String(e.target.value))) {
      setPhoneError(
        "Fel format på telefonnummer (prova 0XXXXXXXXX eller +4XXXXXXXXXX)."
      );
      setPhoneDirty(true);
    } else {
      setPhoneDirty(false);
      setPhoneError("");
    }
  };

  const nameHandler = (e: any) => {
    setName(e.target.value);
    if (e.target.value.length < 2 || !letters.test(String(e.target.value))) {
      setNameError("Fyll i kundens förnamn");
      setNameDirty(true);
    } else {
      setNameDirty(false);
      setNameError("");
    }
  };

  const surnameHandler = (e: any) => {
    setSurname(e.target.value);
    if (e.target.value.length < 2 || !letters.test(String(e.target.value))) {
      setSurnameError("Fyll i kundens efternamn");
      setSurnameDirty(true);
    } else {
      setSurnameDirty(false);
      setSurnameError("");
    }
  };

  const handleSubmit = (e: any) => {
    if (
      true
      // (!nameDirty && name.length > 2) &&
      // (!surnameDirty && surname.length > 2) &&
      // (!phoneDirty && re.test(String(phone))) &&
      // (!emailDirty && isValidEmail(email))
    ) {
      e.preventDefault();
      // const formData = new FormData();
      // formData.append("firstname", name);
      // formData.append("lastname", surname);
      // formData.append("phone", phone);
      // formData.append("email", email);
      // setSubmitDone(true);
      // const requestOptions = {
      //   method: 'POST',
      //   body: formData
      // };

      postPartnerUser(
        fenixLocalPartnerUser.data.creds.tokenAuth,
        fenixLocalPartnerUser.data.creds.partnerId,
        fenixLocalPartnerUser.data
      )
        .then((dataUserSummary: any) => {
          toast.success("Din profil har uppdaterats", {
            style: {
              padding: "30px 45px",
              borderRadius: "5px",
              backgroundColor: "#9EBEA8",
              color: "#fff",
              fontSize: "20px",
              fontWeight: 600,
              textTransform: "uppercase",
              maxWidth: "unset",
            },
            icon: "✓",
          });
        })
        .catch((e: any) => {
          toast.error(
            "Något gick fel. Dina uppgifter kunde inte sparas, försök gärna igen.",
            {
              style: {
                padding: "30px 55px",
                borderRadius: "5px",
                backgroundColor: "#EA3324",
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "Inter",
                textTransform: "uppercase",
                maxWidth: "unset",
              },
              icon: "✕",
            }
          );
        });
    } else {
      e.preventDefault();
      toast.error(
        "Något gick fel. Försök gärna igen eller kontakta oss om problemet kvarstår.",
        {
          style: {
            padding: "30px 55px",
            borderRadius: "5px",
            backgroundColor: "#EA3324",
            color: "#fff",
            fontSize: "20px",
            fontWeight: 600,
            fontFamily: "Inter",
            textTransform: "uppercase",
            maxWidth: "unset",
          },
          icon: "✕",
        }
      );
    }
  };

  function handleShowPackages() {
    setBookModal(true);
    setTypeModal("package");
  }

  const checkHandler = () => {
    setIsChecked(!isChecked);
  };

  function onSubmit(e: any) {}
  return (
    <Layout modalOpen={bookModal} mainClass="relative flex flex-grow flex-col bg-[#FFFEFB] bg-[center_top] bg-no-repeat">
      <Navigation />
      <div className="mx-auto w-full max-w-internal">
        <div className="relative z-10 box-border rounded-b border-x border-b border-solid border-light-grey bg-white px-3 lg:px-24 pb-52 pt-10 lg:pt-24">
          <div className="grid">
            <div className="personal-details">
              <div className="my-account">
                <div className="bg-white">
                  <div className="my-account__title mb-7">
                    <h1 className="font-pprightgothic text-2xl lg:text-4xl font-medium uppercase tracking-widestXl">
                      Kontaktuppgifter
                    </h1>
                  </div>
                  <form name="userInfo">
                    <div className="">
                      <div className="">
                        <div className="mat-form-field mb-5 flex flex-col pb-2">
                          <label
                            className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                            htmlFor="userPersonnummer"
                          >
                            Personnummer
                          </label>
                          <input
                            className="h-13 rounded border border-solid border-[#CFCAB8] bg-[#CFCAB8]/30 indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8]"
                            name="userPersonnummer"
                            id="userPersonnummer"
                            type="text"
                            placeholder="Personnummer"
                            value={personNumber}
                            disabled={true}
                          />
                        </div>
                        <div className="mat-form-field mb-5 flex flex-col pb-2">
                          <label
                            className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                            htmlFor="advisor_first_name"
                          >
                            Förnamn
                          </label>
                          <input
                            className={
                              nameError && nameDirty
                                ? "h-13 rounded border border-solid border-[#EA3324]  bg-[#FFFEFB] indent-5 text-base text-[#EA3324] placeholder:text-sm focus:outline-none"
                                : "h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-base placeholder:text-sm placeholder:text-[#CFCAB8] focus:outline-none"
                            }
                            type="text"
                            placeholder="Förnamn"
                            name="advisor_first_name"
                            id="advisor-first-name"
                            onChange={(e) => nameHandler(e)}
                            value={name}
                            disabled={false}
                          />
                          {nameError && nameDirty && (
                            <label className="error mt-1 text-right text-[#EA3324]">
                              {nameError}
                            </label>
                          )}
                        </div>
                        <div className="mat-form-field mb-5 flex flex-col pb-2">
                          <label
                            className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                            htmlFor="advisor_last_name"
                          >
                            Efternamn
                          </label>
                          <input
                            className={
                              surnameError && surnameDirty
                                ? "h-13 rounded border border-solid border-[#EA3324]  bg-[#FFFEFB] indent-5 text-base text-[#EA3324] placeholder:text-sm focus:outline-none"
                                : "h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8] focus:outline-none"
                            }
                            name="advisor_last_name"
                            id="advisor-last-name"
                            type="text"
                            placeholder=""
                            onChange={(e) => surnameHandler(e)}
                            value={surname}
                            disabled={false}
                          />
                          {surnameError && surnameDirty && (
                            <label className="error mt-1 text-right text-[#EA3324]">
                              {surnameError}
                            </label>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="mat-form-field mb-5 flex flex-col pb-2">
                          <label
                            className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                            htmlFor="email"
                          >
                            Mailadress
                          </label>
                          <input
                            className={
                              emailError && emailDirty
                                ? "h-13 rounded border border-solid border-[#EA3324]  bg-[#FFFEFB] indent-5 text-base text-[#EA3324] placeholder:text-sm focus:outline-none"
                                : "h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8] focus:outline-none"
                            }
                            name="email"
                            id="email"
                            type="text"
                            placeholder="Ange mailadress"
                            onChange={(e) => emailHandler(e)}
                            value={email}
                            disabled={false}
                          />
                          {emailError && emailDirty && (
                            <label className="error mt-1 text-right text-[#EA3324]">
                              {emailError}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="mat-form-field mb-5 flex flex-col pb-2">
                        <label
                          className="mb-2 font-inter text-base font-medium uppercase tracking-widest"
                          htmlFor="currentNumber"
                        >
                          Telefonnummer
                        </label>
                        <input
                          name="userPhone"
                          id="user-phone"
                          type="tel"
                          data-cy="phone-number"
                          className={
                            phoneError && phoneDirty
                              ? "h-13 rounded border border-solid border-[#EA3324]  bg-[#FFFEFB] indent-5 text-base text-[#EA3324] placeholder:text-sm focus:outline-none"
                              : "h-13 rounded border border-solid border-[#CFCAB8] bg-[#FFFEFB] indent-5 text-sm placeholder:text-sm placeholder:text-[#CFCAB8] focus:outline-none"
                          }
                          placeholder="Ange telefonnummer"
                          onChange={(e) => phoneHandler(e)}
                          value={phone}
                        />
                        {phoneError && phoneDirty && (
                          <label className="error text-error mt-1 text-[#EA3324]">
                            {phoneError}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="mt-9 flex justify-end">
                      <button
                        onClick={handleSubmit}
                        className={
                          "mt-1 flex cursor-pointer items-center self-start rounded bg-deep-blue  px-4 py-3 font-interbold font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80"
                        }
                      >
                        Uppdatera dina uppgifter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="mt-12">
                <div className="mb-5 mt-20 border-b border-light-grey pb-3 font-inter text-base font-medium uppercase tracking-widest">
                  Aktivera notiser
                </div>
                <div className="">
                  <div className="flex items-center justify-between">
                    <div className="w-4/6 lg:w-5/6">
                      <p className="mt-5">
                        Se till att hålla dig uppdaterad på dina kunders
                        aktivitet och inaktivitet. Vi hjälper dig hålla koll om
                        någon av dina kunder behöver lite extra stöd längst
                        vägen genom att skicka dig kontinuerliga uppdateringar.
                      </p>
                    </div>
                    <div className="flex w-2/6 lg:w-1/6 justify-end">
                      <Switch
                        isOn={fenixLocalSwitch}
                        onColor="#EF476F"
                        handleToggle={() => {
                          setFenixLocalSwitch(!fenixLocalSwitch);
                          if (
                            fenixLocalPartnerUser &&
                            fenixLocalPartnerUser.data.creds
                          ) {
                            putPartnerUserCustomerSummaryEmail(
                              fenixLocalPartnerUser.data.creds.tokenAuth,
                              fenixLocalPartnerUser.data.creds.partnerId,
                              !fenixLocalPartnerUser.data.wantSummaryEmail
                            )
                              .then((dataUserSummaryEmail: any) => {
                                let currentFenixPartnerUser =
                                  fenixLocalPartnerUser;
                                currentFenixPartnerUser.data.wantSummaryEmail =
                                  !fenixLocalPartnerUser.data.wantSummaryEmail;
                                setFenixLocalPartnerUser(
                                  currentFenixPartnerUser
                                );
                              })
                              .catch((e: any) => console.log(e));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      {bookModal && (
        <Modal
          modal={typeModal}
          onClose={onModalClose}
          onSubmit={onSubmit}
        ></Modal>
      )}
    </Layout>
  );
}
