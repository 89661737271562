import React, {PropsWithChildren, useEffect, useRef} from "react";
import {createPortal} from "react-dom";

import ModalPopup from "./ModalPopup";
import ModalImport from "./ModalImport";
import ModalPackage from "./ModalPackage";
import ModalConfirm from "./ModalConfirm";
import ModalUpdate from "./ModalUpdate";
import {postPartnerCustomers} from "../services/ida";

type Props = PropsWithChildren<{
  onClose: () => void;
  onSubmit: (data: any) => void;
  modal: string;
  data?: any;
  refOutside?: any;
}>;

const Modal = (props: Props) => {

  const elRef = useRef<HTMLDivElement | null>(null);
  if (!elRef.current) {
    elRef.current = document.createElement("div");
  }

  useEffect(() => {
    if (!elRef.current) {
      return;
    }

    const modalRoot = document.getElementById("modal");
    if (modalRoot) {
      modalRoot.appendChild(elRef.current);
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        modalRoot.removeChild(elRef.current);
      };
    }
  }, []);

  const ModalElement = props.modal === "import" ? ModalImport : props.modal === "package" ? ModalPackage : props.modal === "confirm" ? ModalConfirm : props.modal === "update" ? ModalUpdate : ModalPopup;

  return createPortal(
    <ModalElement {...props}>{props.children}</ModalElement>,
    elRef.current
  );
};

export default Modal;
