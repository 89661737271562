import React from "react";

import Checkbox from "./Checkbox";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import toast from "react-hot-toast";

interface MediaProps {
  index: number;
  image?: string;
  id: string;
  title: string;
  link: string;
  hide?: boolean;
  body: string;
  isChecked?: any;
  checkHandler?: any;
}

export default function Article({id, index, image, title, link, body, checkHandler, isChecked, hide}: MediaProps) {
  const [value, copy] = useCopyToClipboard()

  const displayNotice = () => {
    toast.success('Länken kopierad', {
      style: {
        padding: "30px 45px",
        borderRadius: "5px",
        backgroundColor: "#9EBEA8",
        color: "#fff",
        fontSize: "20px",
        fontWeight: 600,
        textTransform: "uppercase",
        maxWidth: "unset",
      },
      icon: '✓',
    });
  }

  return (
    <div className="article relative mt-6 rounded overflow-hidden mb-8 lg:w-8/12">
      <div
        className="mat-checkbox relative cursor-pointer w-full mat-accent"
      >
        <a href={link} target="_blank" className="">
          <div className="mat-checkbox-layout px-0 py-7 mb-0">
          <span className="mat-checkbox-label">
            <div className="flex flex-col">
              <div className="image w-11/12">
                <img src={image} className="w-full rounded"/>
              </div>
              <div className="relative flex flex-col w-11/12">
                <p className="font-inter text-sm font-medium uppercase mt-3">{title}</p>
                <p className="caption text-xxs font-light leading-4 mt-4">{body}</p>
                {checkHandler &&
                  <label htmlFor={"check" + id}
                         className="mat-checkbox-inner-container mt-auto mb-0 -mr-12 ml-auto relative">
                    <Checkbox
                      id={"check" + id} title={title} index={index} checkboxClass={"big"}
                      checkHandler={checkHandler} isChecked={isChecked}/>
                  </label>}
            </div>
            </div>
          </span>
          </div>
          <img
            src="../images/external-link.svg" width={18} height={18} title="Open in new window"
            className="learning-center-link absolute top-7 right-1 cursor-pointer"/>
        </a>
      </div>
      {!hide && <img src="../images/icon-copy.svg" alt="icon copy" width={25} height={25}
           className="absolute right-1 bottom-4 cursor-pointer" onClick={() => {
        copy(link);
        displayNotice()
      }}/>}
    </div>
  );
};