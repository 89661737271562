import React, {useEffect, useContext, useState} from "react";
import Head from "./Head";
import {GlobalState} from "../context/GlobalState";
import {getFromStorage} from "../services/storage";

export default function Layout(props: any) {
  const {fenixPartnerUser, setFenixPartnerUser} = useContext(GlobalState);
  useEffect(() => {
    if (!window.Intercom) {
      return;
    }
    if (
      fenixPartnerUser.data.firstName == "" &&
      fenixPartnerUser.data.lastName == ""
    ) {
      const storageFenixPartnerUser = getFromStorage(
        "fnxPartnerUser",
        fenixPartnerUser
      );
      setFenixPartnerUser(storageFenixPartnerUser);
      // setFenixLocalPartnerUser(storageFenixPartnerUser);
    }
    const ud = {
      api_base: "https://api-iam.intercom.io",
      app_id: "g5vns5pq",
      name: fenixPartnerUser.data.firstName ? fenixPartnerUser.data.firstName : "",
      created_at: Date.now(),
    };
    window.Intercom("boot", ud);
  }, [fenixPartnerUser, window.Intercom]);

  props.modalOpen ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  return (
    <main
      className={props.mainClass} style={{backgroundSize: "100%"}}
    >
      {props.token !== undefined &&
        <img
          src="/images/home-bg.jpg"
          alt="home background"
          width={18}
          height={20}
          className="w-full h-full absolute top-0 left-0 object-cover object-top"
        />}
      <div className="relative flex flex-grow flex-col px-5">
        <div className="container mx-auto pl-0 pr-0">
          <Head
            token={props.token}
            showLogin={props.showLogin}
            status={props.status}
            logoColor={props.logoColor ? props.logoColor : "#0C2632"}
            themeColor={props.themeColor ? "text-" + props.themeColor : "text-deep-blue"}
          />
        </div>
      </div>
      <div className="relative flex flex-grow flex-col px-5 pt-3">
        <div className="container absolute w-full h-1/3 top-20 right-0 left-0 mx-auto"
             style={{background: "rgba(0, 0, 0, 0.25)", borderRadius: "8px", filter: "blur(40px)"}}></div>
        <div
          className={props.containerClass ? props.containerClass : "container relative mx-auto flex flex-grow flex-col rounded-2xl bg-[#FFFEFB] px-3 pt-16 pb-10"}>
          {props.children}
        </div>
      </div>
    </main>
  );
}