import React, {useContext} from "react";
import { useNavigate } from 'react-router-dom';

import {useDevice} from "../hooks/useDevice";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {GlobalState} from "../context/GlobalState";
import {useMediaQuery} from "../hooks/useMediaQuery";

export default function Navigation(props: any) {
  const {fenixPartnerUser, setFenixPartnerUser} = useContext(GlobalState);
  const location = useLocation();
  const navigate = useNavigate();

  //destructuring pathname from location
  const {pathname} = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const matches = useMediaQuery('(min-width: 768px)');

  function handleChange(e: any) {
    navigate('/' + e.target.value);
  }

  return (
    <div className="max-w-internal mx-auto w-full menu">
      <nav className="flex justify-between relative">
        {!useDevice().isMobile ?
          <ul className="relative w-full mb-0 px-0 z-20 flex flex-1 main-nav">
            <li
              className={splitLocation[1] === "kunder" ? "active px-0 flex items-center h-10 md:h-14 relative float-left box-border" : "px-0 flex items-center h-10 md:h-14 relative float-left box-border"}>
              <Link
                className="cursor-pointer text-base uppercase tracking-widestXl font-medium no-underline text-dark-blue block relative"
                to={"/kunder"}>kunder</Link>
            </li>
            <li
              className={splitLocation[1] === "resurser" ? "active px-0 flex items-center h-10 md:h-14 relative float-left box-border" : "px-0 flex items-center h-10 md:h-14 relative float-left box-border"}>
              <Link
                className="cursor-pointer text-base uppercase tracking-widestXl font-medium no-underline text-dark-blue block relative"
                to={"/resurser"}>resurser</Link>
            </li>
            <li
              className={splitLocation[1] === "support" ? "active px-0 flex items-center h-10 md:h-14 relative float-left box-border" : "px-0 items-center h-10 md:h-14 relative float-left box-border"}>
              <Link
                className="cursor-pointer text-base uppercase tracking-widestXl font-medium no-underline text-dark-blue block relative"
                to={"/support"}>support</Link>
            </li>
          </ul> : ""}
        {!useDevice().isMobile ? <ul className="relative flex login-nav">
          <li
            className={splitLocation[1] === "logga" ? "active h-10 md:h-14 login relative float-left " : "h-14 login relative float-left flex items-center"}>
            <Link to={"/logga"}
                  className="cursor-pointer text-base uppercase tracking-widestXl font-medium no-underline text-dark-blue block relative box-border">
              <img src="/images/user.svg" width={22} height={22} alt="user icon" className="mr-1 float-left"/>
              <span>{
                fenixPartnerUser && (fenixPartnerUser.data.firstName || fenixPartnerUser.data.lastName) ?
                  fenixPartnerUser.data.firstName + ' ' + fenixPartnerUser.data.lastName
                  : 'din profil'
              }</span>
              {/*<span className="text-sm text-light-grey ml-2 leading-6">1990.01.01</span>*/}
            </Link>
          </li>
        </ul> : ""}
        {useDevice().isMobile ?
          <select
            name="navigation-select"
            className="navigation-select w-full py-4 px-3 z-10 bg-transparent focus:outline-none font-inter font-medium uppercase"
            onChange={handleChange}
            defaultValue={splitLocation[1]}
          >
            <option
              id={"tab1"}
              value="kunder"
              dangerouslySetInnerHTML={{__html: "Kunder"}}></option>
            <option
              id={"tab2"}
              value="resurser"
              dangerouslySetInnerHTML={{__html: "Resurser"}}></option>
            <option
              id={"tab3"}
              value="support"
              dangerouslySetInnerHTML={{__html: "Support"}}></option>
            <option
              id={"tab4"}
              value="logga"
              dangerouslySetInnerHTML={{
                __html:
                  fenixPartnerUser && (fenixPartnerUser.data.firstName || fenixPartnerUser.data.lastName) ?
                    fenixPartnerUser.data.firstName + ' ' + fenixPartnerUser.data.lastName
                    : 'din profil'
              }}></option>
          </select> : ""}
      </nav>
    </div>
  );
}