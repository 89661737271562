import React, { useState, useEffect } from "react";
import TabContent from "./TabContent";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import Checkbox from "./Checkbox";
import {useDevice} from "../hooks/useDevice";

import { Person } from "../types/person";

let data = require("../assets/data/data.json");

interface Client {
  id: string;
  index: number;
  label: string;
  birth: string;
  value: string;
  phone: string;
  checked: boolean;
  checkHandler: any;
}

interface Package {
  id: number;
  index?: number;
  label?: string;
  value?: number;
  description?: string;
  price?: number;
  packagesAvailableToInvite?: number;
  partnerPackageName?: string;
  batches?: any;
}

interface Resource {
  id: string;
  title: string;
  thumb: string;
  link: string;
  body: string;
  checked: boolean;
}

export default function Tabs(props: any) {
  const [activeTab, setActiveTab] = useState("tab1");
  const [value, copy] = useCopyToClipboard();
  const [noticeVisible, setNoticeVisible] = useState(false);
  const [noticeEmailVisible, setNoticeEmailVisible] = useState(false);
  const [selected, setSelected] = useState("email1");
  const [partnerCustomers, setPartnerCustomers] = useState<Array<Person>>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<Array<number>>([]);
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [packages, setPackages] = useState<Array<Package>>([]);
  const [articles, setArticles] = useState<Array<Resource>>([]);

  const updateCheckStatus = (index: any) => {
    setArticles(
      articles.map((article, currentIndex) =>
        currentIndex === index
          ? { ...article, checked: !article.checked }
          : article
      )
    );
  };

  const updateCheckStatusClients = (index: any) => {
    setPartnerCustomers(
      partnerCustomers.map((user, currentIndex) =>
        currentIndex === index ? { ...user, checked: !user.checked } : user
      )
    );
  };

  useEffect(() => {
    setSelectedCustomers(
      partnerCustomers
        .filter((oneCustomer) => oneCustomer.checked)
        .map((oneCustomer) => Number(oneCustomer.id))
    );
  }, [partnerCustomers]);

  useEffect(() => {
    const uniqueBatches = props.data.batches; //.filter((value:any, index:number, array:any) => array.indexOf(value) === index);

    let currentPackages: Package[] = props.data.packages.map(
      (onePackage: any) => {
        let onePackageCurrent: Package = {
          id: onePackage.id,
          label: onePackage.name,
          value: onePackage.id,
          description: onePackage.description,
          price: onePackage.totalDetails.total,
        };
        return onePackageCurrent;
      }
    );
    setPartnerCustomers(props.data.customers);
    setPackages(currentPackages);

    currentPackages.length > 0 &&
    currentPackages[0] &&
    currentPackages[0].hasOwnProperty("value") &&
    currentPackages[0].value !== undefined &&
    setSelectedPackage(currentPackages[0].value);
    setArticles(data.resources);

    //
  }, [props.data, data.resources]);

  const displayNotice = () => {
    setNoticeVisible(true);

    setTimeout(() => {
      setNoticeVisible(false);
    }, 1000);
  };

  const displayEmailNotice = () => {
    setNoticeEmailVisible(true);

    setTimeout(() => {
      setNoticeEmailVisible(false);
    }, 1000);
  };

  const handleChange = (event: any) => {
    setSelected(event.target.value);
  };

  function onSubmitInvite(e: any) {
    props.onSubmit({
      partnerCustomerIds: selectedCustomers,
      partnerPackageId: selectedPackage,
    });
  }

  function onChangePackage(value: any) {
    setSelectedPackage(value);
  }
  return (
    <div className="mx-auto w-full max-w-internal">
      <div className="relative z-10 bg-white pb-0">
        <div>
          <div className="mt-16 flex flex-col">
            {packages && packages.length > 0 && (
              <div>
                <strong>{packages[0].label}</strong>
              </div>
            )}
          </div>
          {!useDevice().isMobile ?
            <div
              className="mt-8 gap-2 h-72 overflow-y-scroll"
            >
              <div className="invite-head flex">
                <div className="flex w-1/12 items-center"></div>
                <div className="flex w-4/12 items-center py-2.5 px-3 lg:px-7 font-inter text-xs lg:text-base font-medium overflow-hidden">
                  Namn
                </div>
                <div className="flex w-4/12 items-center py-2.5 font-inter text-xs lg:text-sm font-medium overflow-hidden">
                  Mailadress
                </div>
                <div className="flex w-3/12 items-center py-2.5 font-inter text-xs lg:text-sm font-medium overflow-hidden">
                  Telefonnummer
                </div>
              </div>
              {partnerCustomers.map((partnerCustomer, index) => (
                <label
                  htmlFor={"checkbox-" + index}
                  key={index}
                  className={
                    index % 2 === 0
                      ? "invite-row flex cursor-pointer bg-[#E8E3D2]"
                      : "invite-row flex cursor-pointer bg-white"
                  }
                >
                  <div className="flex w-1/12 items-center bg-white">
                    <Checkbox
                      id={"checkbox-" + index}
                      title={partnerCustomer.lastName}
                      isChecked={partnerCustomer.checked}
                      checkboxClass={"small"}
                      index={index}
                      checkHandler={() => updateCheckStatusClients(index)}
                    />
                  </div>
                  <div className="flex w-4/12 items-center py-2.5 px-3 lg:px-7 font-inter text-xs lg:text-base font-medium overflow-hidden">
                    {partnerCustomer.firstName} {partnerCustomer.lastName}
                  </div>
                  <div className="flex w-4/12 items-center py-2.5 px-3 lg:pl-0 font-inter text-xs lg:text-sm font-medium overflow-hidden">
                    {partnerCustomer.email}
                  </div>
                  <div className="flex w-3/12 items-center py-2.5 px-3 lg:pl-0 font-inter text-xs lg:text-sm font-medium overflow-hidden">
                    {partnerCustomer.phone}
                  </div>
                </label>
              ))}
            </div> :

            <div className="mt-8">
              {partnerCustomers.map((partnerCustomer, index) => (
                <label
                  htmlFor={"checkbox-" + index}
                  key={index}
                  className={
                    index % 2 === 0
                      ? "invite-row flex cursor-pointer bg-[#E8E3D2] py-2"
                      : "invite-row flex cursor-pointer bg-white py-2"
                  }
                >
                  <div className="flex w-2/12 justify-center items-center">
                    <Checkbox
                      id={"checkbox-" + index}
                      title={partnerCustomer.lastName}
                      isChecked={partnerCustomer.checked}
                      checkboxClass={"small"}
                      index={index}
                      checkHandler={() => updateCheckStatusClients(index)}
                    />
                  </div>
                  <div className="flex flex-wrap w-10/12">
                    <div className="w-5/12 flex items-center">Namn</div>
                    <div
                      className="flex w-7/12 py-2.5 px-3 lg:px-7 font-inter text-xs lg:text-base font-medium overflow-hidden">
                      {partnerCustomer.firstName} {partnerCustomer.lastName}
                    </div>
                    <div className="w-5/12 flex items-center">Mailadress</div>
                    <div className="flex w-7/12 py-2.5 px-3 lg:pl-0 font-inter text-xs lg:text-sm font-medium overflow-hidden">
                      {partnerCustomer.email}
                    </div>
                    <div className="w-5/12 flex items-center">Telefonnummer</div>
                    <div className="flex w-7/12 py-2.5 px-3 lg:pl-0 font-inter text-xs lg:text-sm font-medium overflow-hidden">
                      {partnerCustomer.phone}
                    </div>
                  </div>
                </label>
              ))}
            </div>
          }
          <div className="mt-16 flex w-full justify-end">
            <img src="/images/fenix-pp-email-template.png" />
          </div>
          <div className="mt-6 lg:mt-16 flex w-full justify-center lg:justify-end">
            <button
              id="skicka_inbjudan"
              className="mt-1 flex items-center self-start rounded bg-deep-blue px-12 py-3.5 font-inter font-semibold uppercase leading-8 tracking-widest text-white hover:bg-deep-blue/80"
              onClick={onSubmitInvite}
            >
              {" "}
              {selectedCustomers.length > 1
                ? "Skicka " + selectedCustomers.length + " inbjudningar"
                : selectedCustomers.length == 1
                  ? "Skicka 1 inbjudan"
                  : "Skicka inbjudan"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
