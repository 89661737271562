import React, { PropsWithChildren } from "react";

const arrowWhite = new URL(
  "../assets/images/button-arrow-white.svg",
  import.meta.url
);
const arrowDark = new URL(
  "../assets/images/button-arrow-dark.svg",
  import.meta.url
);

type Props = PropsWithChildren<{
  onClick: () => void;
  title: string;
  type?: "button" | "submit" | "reset";
  theme?: string;
  id?: string;
  disabled?: boolean;
  size?: "small";
  cssClasses?: string;
  rightArrow?: boolean;
  leftArrow?: boolean;
  icon?: { url: URL; width: number };
  iconComp?: React.ReactNode;
}>;

export default function Button({
  onClick,
  title,
  theme,
  id,
  type = "button",
  disabled,
  size,
  cssClasses = "",
  rightArrow = true,
  leftArrow = false,
  icon,
  iconComp,
  children,
}: Props) {
  let themeClasses =
    "bg-dark-blue text-white border-dark-blue hover:opacity-80";
  let sizeClasses =
    "text-[16px] py-[15px] lg:py-[26px] px-4 lg:px-9 gap-3 lg:gap-4";
  let arrow = arrowWhite;

  if (size === "small") {
    sizeClasses = "border-[1px] lg:border-[2px] pt-4 pb-4 px-11 gap-2";
  }

  if (theme === "yellow") {
    themeClasses =
      "bg-saturate-yellow border-saturate-yellow text-dark-blue hover:opacity-80";
    arrow = arrowDark;
  }

  if (theme === "white") {
    themeClasses =
      "bg-[#FFFEFB] border-dark-blue text-dark-blue hover:opacity-80";
    arrow = arrowDark;
  }

  if (theme === "red") {
    themeClasses = "bg-[#EA3324] border-[#EA3324] text-white hover:opacity-80";
    arrow = arrowDark;
  }

  if (theme === "back-white") {
    themeClasses =
      "bg-[#FFFEFB] border-transparent text-dark-blue underline hover:opacity-80";
    arrow = arrowDark;
  }

  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      className={`flex flex-row items-center justify-between border-[2px] font-inter font-semibold uppercase tracking-wide transition ${sizeClasses} rounded-[5px] text-base leading-tight tracking-widest lg:leading-none ${themeClasses} ${cssClasses}`}
      disabled={disabled}
    >
      {leftArrow && (
        <img
          alt=""
          src={arrow.href}
          width={18}
          style={{ transform: "rotate(180deg)" }}
        />
      )}
      {icon || iconComp ? (
        <>
          {iconComp
            ? iconComp
            : icon && (
                <img
                  className="absolute"
                  alt=""
                  src={icon.url.href}
                  width={icon.width}
                />
              )}

          <div style={icon && { marginLeft: `${icon.width + 20}px` }}>
            {title}
            {children}
          </div>
        </>
      ) : (
        <>
          {title}
          {children}
        </>
      )}
      {rightArrow && <img alt="" src={arrow.href} width={18} />}
    </button>
  );
}
